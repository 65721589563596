import { React, useEffect, useState, useContext } from "react";
import Uuser from "../../assest/Uuser.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import SocialLogBtn from "./SocialLogBtn";
import { themecontext } from "../../context/Themecontexte";
 
function Social_login({ setNewItem, add, setAdd  }) {
  const { theme } = useContext(themecontext);
const {t , i18n}=useTranslation()
 
var isArabic = i18n.language.startsWith('ar');
 

  return (
    <div className={` flex w-full flex-col border-solid border-[1px]  pb-2  rounded-md  px-[.9rem] pt-[1.6rem] `}>
      <div className=  {`flex  w-full ${isArabic ?' flex-row-reverse' :'flex-row' }  gap-2  items-center `}>
        <img className=" w-[3.3rem] h-[3.3rem] " src={Uuser} alt="" />
        <span className=" flex flex-col ">
          <p className=" text-sec text-[.7rem] ">{t('profile.noaccount')} </p>
          <h1 className="  text-[.7rem] ">
          {t('profile.description')}
          </h1>
        </span>
      </div>
      <SocialLogBtn  From={t('profile.Signup')} />
      <h1 className=" text-center pt-[.5rem] text-[.7rem] pb-1 ">{t('profile.OR')}</h1>
      <div className=" text-sec text-[.7rem] text-center ">
        <Link to={'/Signup'} className=" text-main font-medium">{t('profile.Signup')}</Link> {t('profile.OR')}{" "}
        <Link to={'/Signin'} className=" text-main font-medium">{t('profile.signin')}</Link>{t('profile.ifhave')}
      
      </div>
    </div>
  );
}

export default Social_login;
