// Importing necessary modules and dependencies
import { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Dexie from "dexie";
import { sendFcmT } from "../API/SendFcmT";

// Creating a context for managing user-related state
export const Usercontext = createContext();

// Userprovider Component
const Userprovider = ({ children }) => {
  // State variables
  const [User, setUser] = useState(null);
  const [FirstFetch, setFirstFetch] = useState(true);
  const [update, setUpdate] = useState(true);

  // Creating the LocalDB using Dexie
  const Database = new Dexie("Database");
  Database.version(1).stores({
    receipts: "id",
    users: "id",
    cards: "id",
  });


  
  // Get anonymous User
  useEffect(() => {
    if (User?.length > 0 && !User) {
      axios
        .post("/api/clients/anonymous", {})
        .then((response) => {
          const { data } = response;
          Cookies.set("access_token", "Token " + data.access_token);
          setUser(data.user);
        })
        .catch((error) => {
          console.error("Error fetching anonymous User:", error);
        });
    }
  }, []);

  // Saving the User Info in LocalDB
  useEffect(() => {
    const addOrUpdateUserData = async () => {
      try {
        // Fetch existing user from the local DB
        const existingUser = await Database.users.get(User?.id);

        if (!existingUser) {
          // User doesn't exist in the local DB, add it
          await Database.users.put(User);
        } else {
          // User already exists in the local DB, update it
          await Database.users.update(User?.id, User);
        }
      } catch (error) {
        console.error("Error adding or updating User data in local DB:", error);
      }
    };
    if (User) {
      addOrUpdateUserData();
    }
  }, [User]);

  // Fetching user from the local DB
  useEffect(() => {
    const fetchLocalData = async () => {
      try {
        var users = await Database.users.toArray();
        if (users.length > 0) {
          const user = users[0];
          setUser(user);
        }
      } catch (error) {
        console.error("Error fetching User data from local DB:", error);
      }
    };
    if (!User) {
      fetchLocalData();
    }
  }, [User]);

  // Get all the User Info from the server
  useEffect(() => {
    const token = Cookies.get("access_token");

    if (token && User && FirstFetch) {
      axios
        .get("/api/clients/me", {
          headers: {
            Authorization: token,
          },
        })
        .then(({ data }) => {
          setUser(data);  
        })
        .catch((err) => {
          console.error("Error fetching User data from the server:", err);
        });
    }
  }, [update]);

  // Geting the token from the URL and Store it
  useEffect(() => {
    const url = new URL(window.location.href);
    const token = url.searchParams.get("token");
    if (token) {
      Cookies.set("access_token", "Token " + token);
      const access_token = "Token " + token;
      axios
        .get("/api/clients/me", {
          headers: { Authorization: access_token },
        })
        .then(({ data }) => {
          setUser(data);
        })
        .catch((err) => {
          console.error("Error fetching User data from the server:", err);
        });
    }
  }, []);

  // Get User Info for an anonymous user
  useEffect(() => {
    const token = Cookies.get("access_token");

    if (!User && !token) {
      axios
        .post("/api/clients/anonymous", {})
        .then((response) => {
          const { data } = response;
          Cookies.set("access_token", "Token " + data.access_token);
          sendFcmT("Token " + data.access_token);
          setUser(data?.user);
        })
        .catch((error) => {
          console.error("Error fetching anonymous User:", error);
        });
    }
  }, []);

  //  // check if the user in the background of the app 
  
  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     if (document.hidden) {
  //       console.log('App is in the background');
  //     } else {
  //       console.log('App is in the foreground');
  //     }
  //   };

  //   document.addEventListener('visibilitychange', handleVisibilityChange);

  //   // Cleanup the event listener when the component unmounts
  //   return () => {
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //   };
  // }, []);

  // Providing the Usercontext to the component tree
  return (
    <Usercontext.Provider
      value={{
        setFirstFetch,
        User,
        setUser,
        update,
        setUpdate,
      }}
    >
      {children}
    </Usercontext.Provider>
  );
};

// Exporting the Userprovider component
export default Userprovider;
