// Importing necessary modules and dependencies
import { createContext, useEffect, useState, useContext } from "react";
import Dexie from "dexie";
import axios from "axios";
import Cookies from "js-cookie";
import { Usercontext } from "./Usercontexte";
import { checkAndAddCardsToDB } from "../DB/CardDB";
import { UplodingOfflineCard } from "../Functions/UplodingOfflineCard";
import useOnlineStatus from "../hooks/useOnlineStatus";
// Creating a context for managing cards state
export const CardsContext = createContext();

// CardsProvider Component
const CardsProvider = ({ children }) => {
  // State variables
  const [card, setCard] = useState([]);
  const { User } = useContext(Usercontext);
  const [IsCardChange, setIsCardChange] = useState(true);
  const [FirstFetch, setFirstFetch] = useState(true);

  const isOnline = useOnlineStatus();
  // Creating the LocalDB using Dexie
  const Database = new Dexie("Database");
  Database.version(1).stores({
    cards: "id",
  });

  useEffect(() => {
    UplodingOfflineCard(card);
  }, [isOnline]);
 

  // Fetching cards from the local DB OR Server
  useEffect(() => {
    const fetchLocalData = async () => {
      try {
        const allCards = await Database?.cards?.toArray();
        if (allCards.length > 0) {
          setFirstFetch(false);
          // geting all the added cards offline and send them to the server
          setCard(allCards.reverse());
        } else {
          const token = Cookies.get("access_token");

          axios
            .get("/api/clients/fidelity_cards", {
              headers: {
                Authorization: token,
              },
            })
            .then((response) => {
              const { data } = response;
              setCard(data.reverse());
              setFirstFetch(false);
            })
            .catch((error) => {
              console.error("Error fetching cards from the server:", error);
            });
        }
      } catch (error) {
        setFirstFetch(true);
        console.error("Error fetching cards from local DB:", error);
      }
    };
    if (User) {
      fetchLocalData();
    }
  }, [User]);

  // Saving the cards in the local DB
  useEffect(() => {
    if (card) {
      checkAndAddCardsToDB(card);
    }
  }, [User, card]);

  // Providing the CardsContext to the component tree
  return (
    <CardsContext.Provider
      value={{
        card,
        setCard,
        IsCardChange,
        setIsCardChange,
      }}
    >
      {children}
    </CardsContext.Provider>
  );
};

// Exporting the CardsProvider component
export default CardsProvider;
