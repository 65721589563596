import { React, useState, useContext, useEffect } from "react";
import Wlogo from "../../assest/logoW.png";
import Blogo from "../../assest/logoB.png";
import barcodIndex from "../../assest/barcodIndex.svg";
import Wcodebar from "../../assest/miniBarcode.svg";
import { useTranslation } from "react-i18next";
import { Usercontext } from "../../context/Usercontexte";
import codebarW from "../../assest/miniBarcodeB.svg";
import DisplayCodebar from "./DisplayCodebar";
import { themecontext } from "../../context/Themecontexte";
import Menu from "./Menu";

function Navbar() {
  const { theme } = useContext(themecontext);
  const { User } = useContext(Usercontext);
  const [CodeIsopne, setCodeIsopne] = useState(false);
  const [MenuOpen, setMenuOpen] = useState(false);
  const [ScannOpen, setScannOpen] = useState(false);
  const { t, i18n } = useTranslation();
  var isArabic = i18n.language.startsWith("ar");

  return (
    <>
      <div
        className={` flex w-full   ${
          isArabic
            ? " flex-row-reverse pr-[1.19rem]   "
            : " flex-row pl-[1.19rem]  "
        }   flex-row justify-between items-center`}
      >
        <img className=" w-[10rem]     " src={!theme ? Blogo : Wlogo} />
        <div
          className={` flex  ${
            isArabic ? " flex-row-reverse" : " flex-row"
          }  justify-center items-center`}
        >
          <Menu
            setScannOpen={setScannOpen}
            ScannOpen={ScannOpen}
            isOpen={MenuOpen}
            setIsOpen={setMenuOpen}
          />
          <span className=" relative barcode ">
            <img
              onClick={() => setCodeIsopne(!CodeIsopne)}
              className="  pt-2  w-[6rem] h-[6rem]  cursor-pointer"
              src={!theme ? codebarW : Wcodebar}
              alt=""
            />
            <img
              style={{
                visibility: "hidden",
              }}
              id="codeindex"
              className="  z-50 absolute      -top-100  -left-20 w-full"
              src={barcodIndex}
              alt=""
            />
            <span
              style={{ height: "9rem", width: "9rem", visibility: "hidden" }}
              id={""}
              className=" barcode0 absolute"
            ></span>
          </span>
        </div>
      </div>
      {CodeIsopne && (
        <DisplayCodebar
          theme={theme}
          CodeIsopne={CodeIsopne}
          setCodeIsopne={setCodeIsopne}
        />
      )}
    </>
  );
}

export default Navbar;
