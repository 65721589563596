import {React , useContext} from 'react'
import { useTranslation } from "react-i18next";
import closeBtn from '../../assest/CloseBtn.svg'
import { themecontext } from "../../context/Themecontexte";
  
function Mode({OpenMode ,setOpenMode }) {
const {t , i18n} = useTranslation()
  var isArabic = i18n.language.startsWith('ar');
    const { theme ,setTheme} = useContext(themecontext);
   


  return (
    <div
    className={` duration-200  ${
      !OpenMode ? "top-[50%]" : " top-[-100%] "
    }  flex  z-[120] fixed h-full w-full relateve `}
  >
    <div
      onClick={() => setOpenMode(!OpenMode)}
      className={`  flex  fixed h-full w-full   ${
        !OpenMode ? " opacity-50 top-0  " : "opacity-0  top-[-100%]   "
      }  right-0 bg-black `}
    ></div>

    <div
      className={` ${!theme ?' bg-[#212121]' :' bg-white' } ${
        OpenMode ? "  -bottom-[100%]  " : "   bottom-[3%]  "
      }   duration-200    gap-[2rem]  transform  px-[1.2rem]  rounded-md    translate-x-[-50%]  md:h-[60%]  h-[40%]         left-[50%] flex fixed lg:w-[66%]   w-[90%]       flex-col  items-center  justify-center py-6 `}
    >
       
      <img        onClick={() => setOpenMode(!OpenMode)}
 src={closeBtn} className=' absolute top-4 right-4' alt="" />
      <div className=" flex flex-col gap-3 text-center">
        <h1 className=" text-[1.2rem]   font-[600] ">{t('profile.Mode')} </h1>
        <p className=" text-[1rem] text-sec ">{t('profile.Modep')} </p>
      </div>

      <div className=" w-full flex flex-col gap-[.9rem] ">
        <button
          onClick={() => {
            setTheme(false)
            setOpenMode(!OpenMode);
          }}
          className=  {` ${isArabic ?' text-right' :' text-left' }  font-[600] text-[1.1rem] px-[1.1rem] w-full h-[4rem] rounded-[.30rem]  ${!theme ?' bg-main text-white' :'border-solid border-[1px] text-gray-600 ' } `}  
        >
          {t('profile.DMode')}   
        </button>
        <button
          onClick={() => {
            setTheme(true)
            setOpenMode(!OpenMode);
          }}
          className= {`${isArabic ?' text-right' :' text-left' }   font-[600] text-[1.1rem] px-[1.1rem] w-full h-[4rem] rounded-[.30rem]   ${theme ?' bg-main text-white' :'border-solid border-[1px] ' }   `} 
        >
                    {t('profile.WMode')}   
  
        </button>
      </div>
    </div>
  </div>
  )
}

export default Mode