import differenceInDays from "date-fns/differenceInDays";
import format from "date-fns/format";
import { AnimatePresence, motion } from "framer-motion";
import { React, useEffect, useState, useContext } from "react";
import { Translation, useTranslation } from "react-i18next";
import PDFViewer from "./PDFveiw";
import ScannReImg from "../../assest/ScannRe.svg";
import pdfImg from "../../assest/pdf.svg";
import Finlogo from "../../assest/FinLogo.png";
import gmailImg from "../../assest/gmail.svg";
import outlookImg from "../../assest/outLook.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import imgImport from "../../assest/imgImport.svg";
import { Usercontext } from "../../context/Usercontexte";
import DOMPurify from "dompurify";
import { Receiptscontext } from "../../context/Receiptscontext";
import ReceiptDetils from "./ReceiptDetils";

function Receipt({
  receipt,
  FilterOpen,
  filtredata,
  source,
  data,
  json,
  type,
 name,
  date,
  setNewItem,
  id,
  image,
 
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [PdfOpen, setPdfOpen] = useState(false);
  const [logo, setLogo] = useState({
    bg: "",
    img: null,
    from: null,
  });

  const { highlightedItems, setHighlightedItems } = useContext(Receiptscontext);

  const [Status, setStatus] = useState({
    text: "",
    style: "",
  });

  // converting the body into HTML
  const sanitizedHTML = DOMPurify.sanitize(data?.body);

  useEffect(() => {
    // Retrieve the existing array of IDs from localStorage
    const highlighted = localStorage.getItem("highlightedItemIds");

    // If no existing data, set an empty array
    const parsedHighlighted = highlighted ? JSON.parse(highlighted) : [];

    // Set the parsed array as the initial state
    setHighlightedItems(parsedHighlighted);
  }, []);

  const { t, i18n } = useTranslation();
  var isArabic = i18n.language.startsWith("ar");

  const removeItem = (id) => {
    // Remove all occurrences of the ID
    const updatedItemIds = highlightedItems.filter((itemId) => itemId !== id);

    // Save the updated array back to localStorage
    localStorage.setItem("highlightedItemIds", JSON.stringify(updatedItemIds));

    // Update the state to reflect the removal
    setHighlightedItems(updatedItemIds);
  };
  // Splting the data from email to get the email
  const From = data?.from.split("<");

  // Animation
  const items = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
    },
  };

  // Definding the state of the Receipts by the Credit & Total----
  useEffect(() => {
    if (json?.credit === 0) {
      setStatus({ text: t("receipts.paid"), style: " bg-[#49D806] " });
    } else if (json?.credit === json?.total) {
      setStatus({ text: t("receipts.Unpaid"), style: " bg-[#FF0101] " });
    } else if (json?.credit < json?.total && json?.credit != 0) {
      setStatus({
        text: t("receipts.Ppaid"),
        style: " bg-[#FFC51C] w-[70%] px-1 ",
      });
    } else {
      setStatus({
        text: t("receipts.refunded"),
        style: " bg-blue-400 w-[70%] px-1 ",
      });
    }
  }, [FilterOpen, isArabic, filtredata]);
  // ------------------------------------------------

  // Definding the Source of the Receipts by the source ----
  useEffect(() => {
    if (source === "Imported PDF Receipt" ) {
      setLogo({
        img: pdfImg,
        bg: " bg-[#FFFCBF]",
        from:name ? name : t("receipts.ImportedPDFReceipt"),
      });
    } else if (source === "Scanned Receipt") {
      setLogo({
        img: ScannReImg,
        bg: " bg-[#C9FFC8]",
        from: name ? name :t("receipts.ScannedReceipt"),
      });
    } else if (source === "Imported Image Receipt") {
      setLogo({
        img: imgImport,
        bg: " bg-[#DCFFFF]",
        from:name ? name : t("receipts.ImportedImageReceipt"),
      });
    } else if (source === "gmail") {
      setLogo({ img: gmailImg, bg: " bg-[#FFE9E9]" });
    } else if (source === "outlook") {
      setLogo({ img: outlookImg, bg: " bg-[#E9EFFF]" });
    } else {
      setLogo({ img: Finlogo, bg: " bg-[#C9FFC8]" });
    }
  }, [FilterOpen, isArabic, filtredata]);
  // ------------------------------------------------

  return (
    // removing the id from the highlightedItems State when the user click on the recepit
    <motion.div onClick={() => removeItem(id)} variants={items}>
      <div
        // cheking if the id of this receipt is on the highlightedItems (the new receipt)
        style={{
          boxShadow: highlightedItems?.includes(id)
            ? "  0px 8px 28px 0px rgba(89, 78, 241, 0.20)"
            : "",
          border: highlightedItems?.includes(id) ? "2px solid #06C671" : "",
        }}
        onClick={() => {
          setIsOpen(!isOpen);
          setNewItem(false);
        }}
        className={`  w-full px-[1.2rem] py-[.7rem]   h-full flex flex-col items-center  justify-between     border-[1px]   border-[#eeeeee] rounded-[.31rem] `}
      >
        <div
          className={`w-full flex items-center gap-[.8rem]  ${
            isArabic ? " flex-row-reverse" : " flex-row"
          }`}
        >
          <div
            className={` w-full flex  ${
              isArabic ? " flex-row-reverse" : " flex-row"
            }  justify-between items-center`}
          >
            <div
              className={`w-[4rem] h-[2.4rem]  rounded-md flex items-center justify-center ${
                logo.bg
              } ${!isArabic ? "mr-2" : "ml-2"} `}
            >
              <img className=" w-[45%] " src={logo.img} alt="rece" />
            </div>

            <div className=" flex w-full flex-col gap-1">
              <h1 className="  text-[.9rem] font-medium   ">
                {json
                  ? json?.shop_details?.shop_name
                  : source == "gmail" || source == "outlook"
                  ? From[0]
                  : logo.from}
              </h1>
              <p className=" font-normal text-[.75rem] text-sec ">
                {" "}
                {format(new Date(date), "dd-MM-yyyy 'at' HH:mm:ss")}{" "}
              </p>
            </div>

            <button
              className={`   ${
                json ? Status.style : "  bg-[#49D806]"
              }    text-white w-[40%] px-1   text-[.8rem] py-[.3rem]    font-[600]  rounded-[.25rem]`}
            >
              {json ? Status.text : "Paid"}
            </button>
          </div>
          <span className=" p-1 h-4  w-4 justify-center  items-center flex  bg-opacity-30 rounded-[.12rem] bg-main">
            <KeyboardArrowDownIcon fontSize="" className=" text-main" />
          </span>
        </div>


           {/* Receipt Deteils */}
         <AnimatePresence>
          {isOpen &&
            // if the type of the recept is an image
            (type == "image" ? (
              <img
                className=" w-full h-full"
                src={URL.createObjectURL(image)}
                alt=""
              />
            ) : // if the type of the recept is an pdf_file

            type == "pdf_file" ? (
              <iframe
                src={URL.createObjectURL(image)}
                width="100%"
                height="500px"
              />
            ) : // if the type of the recept is an email

            type == "email" ? (
              <div className=" h-[500px]     overflow-auto py-36 ">
                <div
                  className=" pb-40 scale-[.6] -mt-[17rem]  z-30 pt h-full w-full bg-white     "
                  dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
                />
              </div>
            ) : (
              // if the type of the recept is an Json

            <ReceiptDetils  json={json}    setPdfOpen={setPdfOpen}  />
            ))}
        </AnimatePresence>
      </div>

      {PdfOpen && <PDFViewer onclick={setPdfOpen} receipt={receipt} />}
    </motion.div>
  );
}

export default Receipt;

  