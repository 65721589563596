import React, { useState, useCallback, useRef, useContext } from "react";
import Cropper from "react-perspective-cropper";
import loadingAnimation from "../../src/assest/loadingAnimation.svg";
import WestIcon from "@mui/icons-material/West";
import closeBtn from "../assest/CloseBtn.svg";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import { addNewItemID } from "../Functions/addNewItemID";
import { useEffect } from "react";
import { AddImportedReceiptsToDB } from "../Functions/AddImportedReceiptsToDB";
import { useTranslation } from "react-i18next";
import { Receiptscontext } from "../context/Receiptscontext";
export const Cropperr = ({
  image,
  setImage,
  isLoading,
  setResult,
  setIsLoading,
  CardImg,
}) => {
  const [cropState, setCropState] = useState();
  const [isBandW, setisBandW] = useState(true);
  const [res, setRes] = useState(null);
  const [accepte, setAccepte] = useState(false);

  const cropperRef = useRef();

  const { t, i18n } = useTranslation();
  var isArabic = i18n.language.startsWith("ar");

  const { setMassage, setReceiptes, setHighlightedItems } =
    useContext(Receiptscontext);

  const filterCvParams = {
    th: isBandW && !CardImg ,
    grayScale: isBandW  && !CardImg,
  };

  const onDragStop = useCallback((s) => setCropState(s), []);
  const onChange = useCallback((s) => setCropState(s), []);

  const doSomething = async () => {
    try {
      // Assuming cropperRef.current.done returns a Blob
      const blobResult = await cropperRef.current.done({
        preview: true,
        filterCvParams,
      });

      // Convert Blob to File
      const fileResult = new File([blobResult], "yourFileName.jpg", {
        type: "image/jpeg",
      });

       
        setRes(fileResult);
 
      setAccepte(true);
    } catch (e) {
      console.error("Error:", e);
    }
  };
  const returnn = async () => {
    try {
      const res = await cropperRef.current.backToCrop();
      console.log(res);
      setRes(null);
      setAccepte(true);
    } catch (e) {
      console.log("error", e);
    }
  };

  function generateUniqueId() {
    return "_" + Math.random().toString(36).substr(2, 9);
  }

  // accsepting the receipt and store it in the localDB
  async function AccseptReceipt(imageData) {
    if (CardImg) {
      console.log("From the card section");
      setResult(imageData)
    setImage(null)
    } else {
      const created_at = new Date().toISOString();
      if (imageData) {
        try {
          const receiptData = {
            id: generateUniqueId(),
            image: imageData,
            file_type: "image",
            source: image?.Source,
            created_at: created_at,
            name:""
          };
          AddImportedReceiptsToDB(
            receiptData,
            setMassage,
            addNewItemID,
            setReceiptes,
            setHighlightedItems
          );
          setImage(null);
        } catch (error) {
          // Handle the error as needed
        }
      }
    }
  }

  // ending the loading after the image load
  useEffect(() => {
    if (image) {
      setIsLoading(false);
    }
  }, [image]);

  return (
    <div className="w-full h-full bg-black fixed z-50 flex-col pt-10 justify-center pb-20 overflow-auto flex items-center gap-3 top-0 right-0">
      <span
        className={`text-white flex flex-row   ${
          res ? " justify-between" : "  justify-end"
        } w-full absolute top-3 px-5 right-0`}
      >
        {res && (
          <span onClick={returnn}>
            <WestIcon />
          </span>
        )}

        <img onClick={() => setImage(null)} src={closeBtn} alt="" />
      </span>

      <span className=" relative scale-75 flex items-center justify-center w-[80%] h-[40vh] ">
        <span className=" flex flex-row gap-3   absolute transform translate-x-[50%] right-[50%] -translate-y-[50%] top-[50%]  justify-center items-center w-full h-full   ">
          <h1 className=" text-white text-[2rem] font-semibold   ">
            Fintechracy
          </h1>

          <img className=" h-[4rem] w-[4rem]  " src={loadingAnimation} alt="" />
        </span>

        <Cropper
          ref={cropperRef}
          image={CardImg ? CardImg : image?.file} // Use prevImage if it exists, otherwise use the current image
          pointBgColor={"#06c671"}
          pointBorder={"#06c671"}
          lineColor={"#06c671"}
          onChange={onChange}
          onDragStop={onDragStop}
        />
      </span>
      {res ? (
        <div className=" text-white flex flex-row w-[70%] fixed  bottom-6 justify-between  ">
          <button
            onClick={returnn}
            className=" w-14 h-14  bg-red-400 rounded-full"
          >
            <ClearIcon className=" scale-125" />
          </button>
          <button
            onClick={() => AccseptReceipt(res)}
            className=" w-14 h-14  bg-main rounded-full"
          >
            <DoneIcon className=" scale-125" />
          </button>
        </div>
      ) : (
        <span
          className={`h-[3rem]  flex  ${
            isArabic ? " flex-row-reverse  pr-3" : " flex-row  pl-3"
          } fixed bottom-12 w-[60%]  ${
            isArabic ? " right-0" : " left-0 "
          }  justify-between  items-start`}
        >
          <div
            className={` text-white flex flex-col  scale-[.8]    justify-center items-center gap-2   `}
          >
            <button
              onClick={() => {
                setisBandW(!isBandW);
              }}
              className={` ${
                CardImg ? " hidden " : ""
              } relative w-[4rem] h-[1.8rem]  ${
                isBandW ? " bg-main" : " bg-white"
              } border-solid    rounded-full `}
            >
              <span
                className={` ${
                  !isBandW ? "right-[50%] bg-main" : "right-1 bg-white"
                }  ease-out duration-150 h-[85%] w-[1.8rem]  rounded-full  absolute top-[.1rem]   text-white text-center `}
              ></span>
            </button>
            <h1
              className={`text-xs text-center ${
                CardImg ? " hidden " : ""
              }   font-bold`}
            >
              {!isBandW ? (
                <h1>
                  {" "}
                  {t("ScanRe.Original")} {t("ScanRe.Mode")}{" "}
                </h1>
              ) : (
                <h1>
                  {t("ScanRe.BlackWhiteMode")} <br /> {t("ScanRe.Mode")}
                </h1>
              )}
            </h1>
          </div>
          <button
            className="px-6 py-4   bg-main  rounded-md text-white font-bold"
            onClick={doSomething}
          >
            {t("ScanRe.Done")}
          </button>
        </span>
      )}
    </div>
  );
};
