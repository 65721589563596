import {React , useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Usercontext } from "../context/Usercontexte";
import { useTranslation } from "react-i18next";
import { themecontext } from "../context/Themecontexte";
import statusIndex from '../../src/assest/statisticsIndex.svg'
import profileIndex from '../../src/assest/profileIndex.svg'
import offerndex from '../../src/assest/offersIndex.svg'
import cardIndex from '../../src/assest/cardsIndex.svg'
import { Receiptscontext } from "../context/Receiptscontext";
 

function MenuBar() {
  const location = useLocation();
  const {t}  = useTranslation()
  const isActive = (path) => {
    return location.pathname === path;
  };


  const { theme } = useContext(themecontext);
const {highlightedItems} = useContext(Receiptscontext)
 


  const NavItems = [
    {
      title: t('menuBar.Receipts'),
      link: "/",
      svg: (
        <svg
          width="18"
          height="18"
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.502563 14.3974V0.470276L1.5471 1.51481L2.59163 0.470276L3.63617 1.51481L4.68071 0.470276L5.72524 1.51481L6.76978 0.470276L7.81431 1.51481L8.85885 0.470276L9.90338 1.51481L10.9479 0.470276L11.9925 1.51481L13.037 0.470276V14.3974L11.9925 13.3529L10.9479 14.3974L9.90338 13.3529L8.85885 14.3974L7.81431 13.3529L6.76978 14.3974L5.72524 13.3529L4.68071 14.3974L3.63617 13.3529L2.59163 14.3974L1.5471 13.3529L0.502563 14.3974ZM2.59163 10.9156H10.9479V9.52292H2.59163V10.9156ZM2.59163 8.1302H10.9479V6.73749H2.59163V8.1302ZM2.59163 5.34477H10.9479V3.95206H2.59163V5.34477ZM1.89528 12.378H11.6443V2.48971H1.89528V12.378Z"
            fill={` ${isActive("/") ? "#06C671" : "#939191"}  `}
          />
        </svg>
      ),
    },
    {

      title: t('menuBar.Statistics'),
      link: "/Statistics",
      class:'statistics',
      position:"-top-28 -right-12 ",
      index:statusIndex,
      svg: (
        <svg
          width="18"
          height="18"
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.4532 14.018H0.285034V12.5549H13.4532V14.018ZM3.94287 5.97076H1.0166V11.0917H3.94287V5.97076ZM8.33228 0.849792H5.40601V11.0917H8.33228V0.849792ZM12.7217 3.04449H9.79541V11.0917H12.7217V3.04449Z"
            fill={` ${isActive("/Statistics") ? "#06C671" : "#939191"}  `}
          />
        </svg>
      ),
    },
    {

      title: t('menuBar.Cards'),
      link: "/Cards",
      class:'cards',
      position:"-top-32 right-0 ",
      index:cardIndex,
      svg: (
        <svg
          width="18"
          height="18"
          viewBox="0 0 17 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.1343 1.48871H2.8385C1.6893 1.48871 0.75769 2.42032 0.75769 3.56952V11.2982C0.75769 12.4474 1.6893 13.379 2.8385 13.379H14.1343C15.2835 13.379 16.2151 12.4474 16.2151 11.2982V3.56952C16.2151 2.42032 15.2835 1.48871 14.1343 1.48871Z"
            stroke={` ${isActive("/Cards") ? "#06C671" : "#939191"}  `}
            stroke-width="1.18903"
            stroke-linecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M0.75769 5.05583H16.2151M3.73027 9.06882H5.51382V9.81196H3.73027V9.06882Z"
            stroke={` ${isActive("/Cards") ? "#06C671" : "#939191"}  `}
            stroke-width="2.22944"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {

      title:  t('menuBar.Offers'),
      link: "/Offers",
      class:'offers',
      position:"-top-28 right-10 ",
      index:offerndex,
      svg: (
        <svg
          width="18"
          height="18"
          viewBox="0 0 16 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.7631 7.12571L8.15873 0.521319C7.89456 0.257144 7.52764 0.0957031 7.12404 0.0957031H1.9873C1.18009 0.0957031 0.519653 0.756142 0.519653 1.56335V6.70009C0.519653 7.10369 0.681094 7.47061 0.952608 7.74212L7.557 14.3465C7.82117 14.6107 8.18808 14.7721 8.59169 14.7721C8.99529 14.7721 9.3622 14.6107 9.62637 14.3392L14.7631 9.20242C15.0346 8.93825 15.1961 8.57134 15.1961 8.16774C15.1961 7.76413 15.0273 7.38988 14.7631 7.12571ZM8.59169 13.3118L1.9873 6.70009V1.56335H7.12404V1.55601L13.7284 8.1604L8.59169 13.3118Z"
            fill={` ${isActive("/Offers") ? "#06C671" : "#939191"}  `}
          />
        </svg>
      ),
    },
    {
      title:  t('menuBar.Account'),
      link: "/Account",
      class:'profile',
      position:"-top-24 right-16 ",
      index:profileIndex,
      svg: (
        <svg
          width="18"
          height="18"
          viewBox="0 0 16 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.50061 12.3089C1.50061 11.4469 1.84302 10.6203 2.45251 10.0108C3.06201 9.40131 3.88866 9.0589 4.75061 9.0589H11.2506C12.1126 9.0589 12.9392 9.40131 13.5487 10.0108C14.1582 10.6203 14.5006 11.4469 14.5006 12.3089C14.5006 12.7399 14.3294 13.1532 14.0247 13.4579C13.7199 13.7627 13.3066 13.9339 12.8756 13.9339H3.12561C2.69463 13.9339 2.28131 13.7627 1.97656 13.4579C1.67182 13.1532 1.50061 12.7399 1.50061 12.3089Z"
            stroke={` ${isActive( `/Account`) || isActive( `/PersonalInfo`) ? "#06C671" : "#939191"}  `}
            stroke-width="1.5"
            strokeLinejoin="round"
          />
          <path
            d="M8.00061 5.8089C9.3468 5.8089 10.4381 4.71759 10.4381 3.3714C10.4381 2.0252 9.3468 0.933899 8.00061 0.933899C6.65442 0.933899 5.56311 2.0252 5.56311 3.3714C5.56311 4.71759 6.65442 5.8089 8.00061 5.8089Z"
            stroke={` ${isActive( `/Account`) || isActive( `/PersonalInfo`) ? "#06C671" : "#939191"}  `}
            stroke-width="1.5"
          />
        </svg>
      ),
    },
  ];

  return (
    <div  id="menubar" className=  {`fixed  z-20  menubar   ${ !theme ? ' bg-[#212121] border-[#424242] ' : ' bg-white  border-[#F1F1F1]'  }  bottom-0      w-full right-0 h-16    border-t-[1px] border-solid flex flex-row items-center justify-between p-6 `} >
      {NavItems.map((i) => (
        <span   className={` relative ${i?.class} `}>
          <img style={{
           visibility:'hidden' 
           }}   id={i.class}  className= {` scale-[3] w z-50 absolute ${i.position} w-full`} src={i.index}  />
        <span   className={`${i?.class+'0'} absolute`}>
      </span>
          <Link
          to={i.link}
          className={`relative  text-[10px] ${
            isActive(i.link) ? " text-main" : "text-[#7B7B7B]"
          } text-[#7B7B7B] flex flex-col gap-1 items-center`}
        >
           {i.svg}
          {i.title}
     {highlightedItems.length > 0 && i.link == "/" &&  <span className=" absolute -top-[.4rem] right-1  h-3 w-3 bg-green-400 rounded-full ">  </span>}
        </Link> 
        </span>
      ))}
    </div>
  );
}

export default MenuBar;
