// Importing necessary dependencies and components
import React, { useState, useRef, useEffect, useContext } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import menuIndex from "../../assest/MenuIndex.svg";
import importRefromDevice from "../../assest/ImportRe.svg";
import importRefromEmail from "../../assest/ImportEmail.svg";
import { addNewItemID } from "../../Functions/addNewItemID";
import ScannRe from "../../assest/ScannRe.svg";
import { AddImportedReceiptsToDB } from "../../Functions/AddImportedReceiptsToDB";
import { Cropperr } from "../Cropper";
import { themecontext } from "../../context/Themecontexte";
import { useTranslation } from "react-i18next";
import Social from "../Socials";
import Compressor from "compressorjs";
import { Receiptscontext } from "../../context/Receiptscontext";
import { useClickOutside } from "../../hooks/useClickOutside";

// Define the Menu component
function Menu({ isOpen, setIsOpen }) {
  // State variables
  const [img, setImg] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSocialopen, setIsSocialopen] = useState(false);
  const { setMassage, setReceiptes, setHighlightedItems } =
    useContext(Receiptscontext);
  const { theme } = useContext(themecontext);
  const refMenu = useRef();
  const { t, i18n } = useTranslation();
  var isArabic = i18n.language.startsWith("ar");

 
// Closing the Menu By clicking outSide it
  const closeMenu = () => {
    setIsOpen(false);
  };
  useClickOutside(refMenu, closeMenu);
// ------------------------------------


  // Generate unique ID for the receipt
  function generateUniqueId() {
    return "_" + Math.random().toString(36).substr(2, 9);
  }

  // Handle file selection
  async function onFileSelection(e, source) {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const fileType = selectedFile.type;

      // If it's an image
      if (fileType.startsWith("image/")) {
        setImg({
          file: selectedFile,
          Source: source,
        });
      } else if (fileType === "application/pdf") {
        // If it's a PDF
        const created_at = new Date().toISOString();

        const receiptData = {
          id: generateUniqueId(),
          image: selectedFile,
          source: "Imported PDF Receipt",
          file_type: "pdf_file",
          created_at: created_at,
        };
        // Add PDF receipt to the local database
        AddImportedReceiptsToDB(
          receiptData,
          setMassage,
          addNewItemID,
          setReceiptes,
          setHighlightedItems
        );
      } else {
        console.log(`Unsupported file type`);
      }
    }
  }

  return (
    <>
      {/* Main menu container */}
      <div className="menu relative">
        {/* Toggle button for opening/closing the menu */}
        <span
          ref={refMenu}
          onClick={() => setIsOpen(!isOpen)}
          className="text-main bg-main bg-opacity-25 rounded-sm w-full h-[6rem] py-[.08rem] px-3 -mr-2 cursor-pointer"
        >
          <KeyboardArrowDownIcon />
        </span>
        <span id={""} className="menu0"></span>
        {/* Hidden image for menu styling */}
        <img
          style={{
            visibility: "hidden",
          }}
          id="menu"
          className="z-50 absolute scale-[3] top-24 -left-5 w-full"
          src={menuIndex}
          alt=""
        />
        {/* Actual menu content */}
        <div
          className={`text-sec flex flex-col duration-150 ease-out text-sm overflow-hidden justify-between w-[13rem] ${
            isOpen ? "h-[6rem] py-1 border-[1px]" : "h-0 py-0 border-[0px]"
          } ${
            !theme ? "bg-[#212121]" : "bg-white"
          } absolute top-10 border-solid  border-[#F2F2F2] rounded-md  ${
            isArabic ? " -left-[200%] " : "  right-[-150%]"
          } shadow-lg`}
        >
          {/* Scan receipt option */}
          <label
            onClick={() => setIsLoading(true)}
            className={` gap-1 flex ${
              isArabic ? " flex-row-reverse" : " flex-row"
            } items-center px-2`}
            htmlFor="ScannReceipt"
          >
            <img src={ScannRe} alt="" />
            {t("Menu.Scann")}
          </label>
          {/* File input for scanning receipt */}
          <input
            className="z-50 w-full h-6"
            type="file"
            id="ScannReceipt"
            onChange={(e) => onFileSelection(e, "Scanned Receipt")}
            accept="image/*"
            style={{ display: "none" }}
            capture="environment"
          />
          {/* Separator line */}
          <span className="w-full h-[1px] bg-[#F2F2F2]"></span>
          {/* Import receipt from device option */}
          <label
            onClick={() => setIsLoading(true)}
            className={` gap-1 flex ${
              isArabic ? " flex-row-reverse" : " flex-row"
            } items-center px-2`}
            htmlFor="importFromEmail"
          >
            <img src={importRefromDevice} alt="" />
            {t("Menu.ImportD")}
          </label>
          {/* File input for importing receipt from device */}
          <input
            className="z-50 w-full h-6"
            type="file"
            style={{ display: "none" }}
            id="importFromEmail"
            onChange={(e) => onFileSelection(e, "Imported Image Receipt")}
            accept="image/*, application/pdf"
          />
          {/* Separator line */}
          <span className="w-full h-[1px] bg-[#F2F2F2]"></span>
          {/* Import receipt from email option */}
          <p
            onClick={() => setIsSocialopen(true)}
            className={` gap-1 flex ${
              isArabic ? " flex-row-reverse" : " flex-row"
            } items-center px-2`}
          >
            <img src={importRefromEmail} alt="" /> {t("Menu.ImportE")}
          </p>
        </div>
      </div>
      {/* Cropper component for image cropping */}
      {img && (
        <Cropperr
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setImage={setImg}
          image={img}
        />
      )}
      {/* Social component */}
      <Social
        text={t("importReFromEmail.ChooseEmailProvider")}
        isOpen={isSocialopen}
        setIsOpen={setIsSocialopen}
      />
    </>
  );
}

// Export the Menu component
export default Menu;
