// Importing necessary modules and dependencies
import { React, useState, useEffect, useContext , useRef } from "react";
import { Link, useParams } from "react-router-dom";
import cmaera from "../../assest/camera.svg";
import Barcode from "react-barcode";
import TopBar from "../TopBar";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LoadingBtn from "../LoadingBtn";
import { useTranslation } from "react-i18next";
import { themecontext } from "../../context/Themecontexte";
import { CardsContext } from "../../context/Cardscontext";
import DeleteCard from "./DeleteCard";
import {useClickOutside} from '../../hooks/useClickOutside'

// CardDetails Component
function CardDetails() {
  // State variables
  // const [card, setcard] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [Imgcolor, setImgcolor] = useState();
  const [OptionOpen, setOptionOpen] = useState(false);
  const [text, setText] = useState(""); // State for the input text
  const [Gcolor, setGColor] = useState("#FFFFFF");
  const [deleting, setDeleting] = useState(false);
  const [OneCard, setOneCard] = useState();
 const OptionRef = useRef()
  const { setIsCardChange, setCard, IsCardChange, card } =
    useContext(CardsContext);
  const { theme } = useContext(themecontext);

  // React Router hook for navigation
  const { id } = useParams();

  // Translation hook
  const { t, i18n } = useTranslation();
  var isArabic = i18n.language.startsWith("ar");



  // Closing the Menu By clicking outSide it
  const closeOption = () => {
    setOptionOpen(false);
  };
  useClickOutside(OptionRef, closeOption);
// ------------------------------------

  // Function to generate color based on input text
  const generateColor = (inputText) => {
    // Simple hashing function
    const hashCode = (str) => {
      let hash = 0;
      for (let i = 0; i < str?.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      return hash;
    };

    // Convert the hashed value to a color
    const intToRGB = (i) => {
      const c = (i & 0x00ffffff).toString(16).toUpperCase();
      return "00000".substring(0, 6 - c.length) + c;
    };

    const hash = hashCode(inputText);
    const generatedColor = `#${intToRGB(hash)}`;

    return generatedColor;
  };

  useEffect(() => {
    const oneCard = card.find((item) => item.id == id);
    setOneCard(oneCard);
    setText(oneCard?.store_name);
    const newColor = generateColor(oneCard?.store_name);
    setGColor(newColor);
  }, [card]);
 

  return (
    <div className=" flex flex-col  pb-20  ">
      <span className=" flex flex-row py-[1.2rem] px-[1.3rem] justify-between">
        {/* TopBar */}
        <TopBar text={t("topbar.CardD")} />

        {/* More Options */}
        <span ref={OptionRef} onClick={() => setOptionOpen(!OptionOpen)} className="relative">
          <MoreVertIcon />
          {OptionOpen && (
            <div 
              className={`  flex flex-col absolute top-6 right-3 justify-between h-[6rem] w-[8rem] rounded-md shadow-lg    items-start font-medium  ${
                theme ? " bg-white" : " bg-[#212121] "
              } `}
            >
              {/* Edit Card Link */}
              <Link
                to={`/Cards/addcartmanully/${id}`}
                className=" p-[.8rem] border-solid border-b-[1px] font-[500] border-gray-300 w-full "
              >
                {t("card.EditC")}
              </Link>

              {/* Delete Card Button */}
              <h1
                onClick={() => setDeleting(true)}
                className=" p-[.8rem] text-end font-[500] "
              >
                {t("card.DeleteC")}
              </h1>
            </div>
          )}
        </span>
      </span>

      {/* Card Display */}
      <div
        style={
          !OneCard?.front_image
            ? { backgroundColor: Gcolor }
            : {
                backgroundImage: `url(${OneCard?.front_image || URL.createObjectURL(OneCard?.front_image)  })`,
                backgroundSize: "250%",
              }
        }
        className={` flex flex-col gap-6 bg-no-repeat  items-center justify-start  bg-cover bg-top   text-white  font-[600] text-[1.7rem] p-[1.2rem]    w-full h-[10rem]  `}
      >
        <h1>{OneCard?.store_name} </h1>

        {/* Barcode Display */}
        <div className="  rounded-xl text-xs   max-w-full p-2  bg-white  border-solid border-[1px]  h-[10rem]  flex items-center justify-center  ">
          <span className={` scale-[.85]    sm:scale-[1.6]`}>
            <Barcode
              value={OneCard?.code}
              height={"100%"}
              displayValue={false}
              width={OneCard?.code.length > 8 ? "2rem" : "3rem"}
            />
          </span>
        </div>
      </div>

      {/* Card Details Section */}
      <div className=" flex flex-col  gap-[1rem] pt-[6rem] px-[1.2rem] ">
        <h1 className=" text-[1.1rem]  font-medium ">{t("card.Notes")}</h1>
        <input
        disabled={true}
          placeholder="Notes"
          value={OneCard?.notes == "undefined" ? "" : OneCard?.notes}
          type="text"
          className=" h-[3.7rem] px-[1rem] bg-transparent  w-full rounded-[.32rem] border-solid border-[1px]  "
        />

        {/* Card Images Display */}
        <h1 className=" text-[1.1rem]  font-medium">{t("card.CardP")} </h1>
        <div className=" flex flex-row w-full rounded-lg border-solid border-[1px] h-[12rem] justify-center gap-2 items-center px-10  ">
          {OneCard?.front_image ? (
            <img
              className={` rounded-lg w-[9rem] h-[7rem]  border-solid border-[1px]   object-cover `}
              src={ typeof OneCard?.front_image === 'string' ? OneCard?.front_image  : URL.createObjectURL(OneCard?.front_image)}
              alt="front_image"
            />
          ) : (
            <span className=" rounded-lg w-[9rem] flex flex-col justify-center items-center h-[7rem]  border-solid border-[1px]   object-cover ">
              <img className=" w-[2.4rem] " src={cmaera} alt="" />
              <p>front</p>
            </span>
          )}
          {OneCard?.back_image ? (
            <img
              className=" rounded-lg w-[9rem] h-[7rem] border-solid border-[1px]   object-cover   "
              src={  (typeof OneCard?.back_image === 'string' ? OneCard?.back_image  : URL.createObjectURL(OneCard?.back_image))}
              alt="back_image"
            />
          ) : (
            <span className=" rounded-lg w-[9rem] flex flex-col justify-center items-center h-[7rem]  border-solid border-[1px]   object-cover ">
              <img className=" w-[2.4rem] " src={cmaera} alt="" />
              <p>back</p>
            </span>
          )}
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      {deleting && (
        <DeleteCard
          t={t}
          id={id}
          setisLoading={setisLoading}
          setDeleting={setDeleting}
          setIsCardChange={setIsCardChange}
          setCard={setCard}
          theme={theme}
          IsCardChange={IsCardChange}
        />
      )}

      {/* Loading Indicator */}
      {isLoading && (
        <div className=" flex items-center z-50 justify-center fixed bg-black opacity-30 w-full h-full">
          <LoadingBtn color={"#fff"} isLoading={isLoading} />
        </div>
      )}
    </div>
  );
}

// Exporting the CardDetails component
export default CardDetails;
