import React, { useContext, useEffect, useState } from "react";
import TopBar from "../compenent/TopBar";
import { linkEmailWithAccount } from "../API/importEmails";
import Cookies from "js-cookie";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import GmailImg from "../../src/assest/gmail.png";
import OutlookImg from "../../src/assest/outLook.png";
import LoopIcon from "@mui/icons-material/Loop";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Social from "../compenent/Socials";
import { themecontext } from "../context/Themecontexte";
import { useTranslation } from "react-i18next";

function LinkedAccount() {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isSocialOpen, setIsSocialOpen] = useState(false);
  const {theme} = useContext(themecontext)
  const {t,i18n}= useTranslation()
  var isArabic = i18n.language.startsWith('ar'); 
  return (
    <>
      <div className= " text-left  pt-[1.6rem] px-3 h-full relative">
        <TopBar text={t('importReFromEmail.MyReceipts')} />
        <div className=" pt-[1.7rem]  flex flex-col ">
          <h1 className={`font-semibold  ${isArabic ? " text-right" : " text-left" } `} >
            {t('importReFromEmail.ManageEmailAccounts')}
          </h1>
          <div>
            <h1 className=" py-[1rem] ">Gmail</h1>
            <div className="  flex flex-col gap-2">
              <span className=" flex flex-row items-center justify-between w-full h-[3.6rem]  border-solid  px-[.8rem]  py-[.9rem] border-[1px] rounded-md ">
                <div className=" flex gap-3 justify-center items-center">
                  <img className=" h-[1.8srem]  " src={GmailImg} alt="" />
                  <span>
                    <h1 className=" text-[.9rem] font-semibold ">
                      aicha.merghadi06@gmail.com
                    </h1>
                    <p className=" text-sec text-[.7rem] ">Synced</p>
                  </span>
                </div>
                <span onClick={() => setIsOpen(!isOpen)} className=" relative">
                  <MoreVertIcon />
                  <div
                    className={`text-sm absolute flex flex-col rounded-md right-5 duration-150  justify-between ${
                      isOpen
                        ? " h-[4rem]  py-1 border-[1px]"
                        : " h-0 py-0 border-[0px]"
                    }    overflow-hidden  border-solid  top-5 w-[6rem]   ${!theme ?' bg-[#212121] ':' bg-white' } `}
                  >
                    <h1 className="  active:bg-opacity-25 flex flex-row gap-1 items-center">
                      {" "}
                      <LoopIcon className="  scale-75 " /> Sync now
                    </h1>
                    <span className=" w-full h-[1px] bg-gray-200 "></span>
                    <h1 className="  active:bg-opacity-25 flex flex-row gap-1 items-center">
                      <DeleteIcon className="  scale-75 " /> Remove
                    </h1>
                  </div>
                </span>
              </span>
            </div>
          </div>

          <div>
            <h1 className=" py-[1rem] ">Outlook</h1>
            <div className="  flex flex-col gap-2">
              <span className=" flex flex-row items-center justify-between w-full h-[3.6rem]  border-solid  px-[.8rem]  py-[.9rem] border-[1px] rounded-md ">
                <div className=" flex gap-3 justify-center items-center">
                  <img
                    className=" h-[1.8srem] w-[1.8rem]  "
                    src={OutlookImg}
                    alt=""
                  />
                  <span>
                    <h1 className=" text-[.9rem] font-semibold ">
                      aicha.merghadi06@gmail.com
                    </h1>
                    <p className=" text-sec text-[.7rem] ">Synced</p>
                  </span>
                </div>
                <span onClick={() => setIsOpen1(!isOpen1)} className=" relative">
                <MoreVertIcon />
                <div
                    className={`text-sm absolute flex flex-col rounded-md right-5 duration-150  justify-between ${
                      isOpen1
                        ? " h-[4rem]  py-1 border-[1px]"
                        : " h-0 py-0 border-[0px]"
                    }    overflow-hidden  border-solid  top-5 w-[6rem]   ${!theme ?' bg-[#212121] ':' bg-white' } `}
                  >
                    <h1 className="  active:bg-opacity-25 flex flex-row gap-1 items-center">
                      {" "}
                      <LoopIcon className="  scale-75 " /> Sync now
                    </h1>
                    <span className=" w-full h-[1px] bg-gray-200 "></span>
                    <h1 className="  active:bg-opacity-25 flex flex-row gap-1 items-center">
                      <DeleteIcon className="  scale-75 " /> Remove
                    </h1>
                  </div>
                </span>

              </span>
            </div>
          </div>
        </div>
        <button
          onClick={() => setIsSocialOpen(!isSocialOpen)}
          className="  absolute bottom-20 right-4  p-2   rounded-md  bg-main z-30 flex flex-row items-center gap-1  text-white "
        >
          {" "}
          <AddIcon /> {t('importReFromEmail.AddAccount')}
        </button>
      </div>
      <Social
      text={'Please select your email provider below.'}
        setIsOpen={setIsSocialOpen}
        isOpen={isSocialOpen}
      />
    </>
  );
}

export default LinkedAccount;
