import { React, useEffect, useState, useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { themecontext } from "../../context/Themecontexte";
import { SpendingChartOptions } from "../../assest/data/ChartOption";
import { ProcessChartData } from "../../Functions/ProcessChartData";

const SpendingChart = ({ setTotalSpent, state, Hdata, Tdata, Ldata, Avrge, Period }) => {
  // Theme context
  const { theme } = useContext(themecontext); // Replace with actual context

  // State for chart data
  const [chartData, setTchartData] = useState([]);
  const [HchartData, setHchartData] = useState([]);
  const [LchartData, setLchartData] = useState([]);
  const [AChartData, setAChartData] = useState([]);

  // Translation
  const { t } = useTranslation();

  // Process and update total spent amount when 'Total spending' array changes
  useEffect(() => {
    const { totalAmount, processedData } = ProcessChartData(Tdata, Period, true);
    setTchartData(processedData);
    setTotalSpent(totalAmount);
    console.log("totalAmountTOTAL");
    console.log(totalAmount);
  }, [Tdata]);

  // Process and update low spending data when 'Low spending' array changes
  useEffect(() => {
    const { processedData } = ProcessChartData(Ldata, Period, false);
    setLchartData(processedData);
  }, [Ldata]);

  // Process and update high spending data when 'High spending' array changes
  useEffect(() => {
    const { processedData } = ProcessChartData(Hdata, Period, false);
    setHchartData(processedData);
  }, [Hdata]);

  // Process and update average spending data when 'Average spending' array changes
  useEffect(() => {
    const { processedData } = ProcessChartData(Avrge, Period, false);
    setAChartData(processedData);
  }, [Avrge]);

  // Array of indices
  const index = [0, 1, 2, 3];

  // Create spending chart options
  const Option = SpendingChartOptions(
    state,
    theme,
    HchartData,
    LchartData,
    chartData,
    AChartData
  );
  return (
    <div className=" relative w-full h-full  mt-[.7rem] ">
      <div className="  w-full flex  pb-[1.3rem]  rounded-[.32rem] pt-[1.7rem] pl-[0rem] pr-[1.8rem] border-solid border-[1px]    flex-col   h-[300px] ">
        <div className=" flex flex-row items-center w-full gap-1 justify-between">
          <span
            className={`w-full  justify-end flex flex-row gap-1 items-center ${
              state >= 2 ? " text-[.94rem]" : "text-[1.1rem]"
            }  font-[400]`}
          >
            <span
              className={`w-3 h-3 rounded-full  ${
                state == 0
                  ? " bg-main"
                  : state == 1
                  ? " bg-yellow-400"
                  : " bg-red-500"
              }    `}
            ></span>{" "}
            <h1>
              {state == 0
                ? t("Stats.TSpending")
                : state == 1
                ? t("Stats.AT") + " " + t("Stats.T")
                : t("Stats.HT") + " " + t("Stats.T")}
            </h1>
          </span>

          {state >= 2 && (
            <span
              className={` w-full  justify-end flex flex-row gap-1 ${
                state >= 2 ? " text-[.94rem]" : "text-[1.1rem]"
              }  items-center  font-[400] `}
            >
              <span className={`w-3 h-3 rounded-full bg-main  `}></span>{" "}
              <h1>{t("Stats.LT") + " " + t("Stats.T")}</h1>
            </span>
          )}
        </div>

        <ReactApexChart
          options={Option}
          series={Option.series}
          type="area"
          height={"100%"}
          width={"102%"}
        />
      </div>
      <span className=" w-full justify-center items-center pt-[.7rem]  flex flex-row">
        {index.map((i) => (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 7 7"
            fill="none"
          >
            <circle
              cx="3.36435"
              cy="3.604"
              r="3"
              fill={i == state ? "#06C671" : "#F0EFEF"}
            />
          </svg>
        ))}
      </span>
    </div>
  );
};

export default SpendingChart;
