import {React , useEffect, useState } from 'react'
import AskForTour from './AskForTour'
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import '../App.css'
import { useNavigate } from 'react-router-dom'

function TakeTour({setTour}) {

   
    const [Askopen , setAskopen] = useState(true)
 

    const [tourSteps, setTourSteps] = useState([
      {
        id: 'step-1',
        text: 'Your Barcode',
        P:"Click here to show your barcode  for the cashier to scan and receive your receipt",
        targetSelector: '.barcode0',
        target:".barcode",
        index:"codeindex",
        possition:'top-[30%]'
      },
      {
        id: 'step-2',
        text: 'Menu',
        P:"Click here in case you want to import your receipts from your email or to add your paper receipt",
        targetSelector: '.menu0',
        target:".menu",
        index:"menu",
        possition:' top-[30%]'
      },
      {
        id: 'step-3',
        text: 'Your Receipt',
        P:"Click here to view the details of your receipt.",
        targetSelector: '.ReceiptBtn0',
        target:".ReceiptBtn",
        index:"Receipt",
        possition:' bottom-[30%]'
      },
      {
        id: 'step-4',
        text: 'Statistics',
        P:"Click here to check your spending patterns and take charge of your financial journey", 
        targetSelector: '.statistics0',
        target:".statistics",
        index:"statistics",
        possition:' bottom-[30%]'

      },    
       {
        id: 'step-5',
        text: 'Cards',
        P:"Click here to effortlessly organize all your loyalty cards in one secure place.",
        targetSelector: '.cards0',
        target:".cards",
        index:"cards",
        possition:' bottom-[30%]'

      },
         {
        id: 'step-6',
        text: 'Offers',
        P:" Click here to explore exclusive offers from your favorite stores",
        targetSelector: '.offers0',
        target:".offers",
        index:"offers",
        possition:' bottom-[30%]'

      }, 
      {
        id: 'step-7',
        text: 'Finally your profile',
        P:"Click here to manage your info and settings and by the way Don't be  a stranger create your account and join us 😄",
        targetSelector: '.profile0',
        target:".profile",
        index:"profile",
        possition:' bottom-[30%]'

      },
    ]);
  
      const [currentStep, setCurrentStep] = useState(null);
      const  navto = useNavigate()

    useEffect(()=>{
      if(currentStep >= 0 ){
        highlightTarget();
      }
    },[currentStep] )
      
        const nextStep = () => {
    if(currentStep < 1 ){
      navto('/')
    }
 
      if (currentStep < tourSteps.length - 1) {
        setCurrentStep(currentStep + 1);
      
      } else {
        setTour(true)
         localStorage.setItem('tour',true)
         const menubar = document.getElementById('menubar')
         menubar?.classList.remove('menubar-style');
         
         const currentTarget = document.querySelector(tourSteps[currentStep].targetSelector);
        const currentTargetElement = document.querySelector(tourSteps[currentStep].target);
        const index = document.getElementById(tourSteps[currentStep].index);
        index?.classList.remove('index-target');
        currentTarget?.classList.remove('highlighted');
        currentTargetElement?.classList.remove('highlighted-target');
      }
    };

    const prevStep = () => {
      if(currentStep <=2 ){
        navto('/')
      }      
      if (currentStep > 0) {
        // Remove 'highlighted' class from the current target
        const currentTarget = document.querySelector(tourSteps[currentStep].targetSelector);
        const currentTargetElement = document.querySelector(tourSteps[currentStep].target);
        const index = document.getElementById(tourSteps[currentStep].index);
        index?.classList.remove('index-target');
        currentTarget?.classList.remove('highlighted');
        currentTargetElement?.classList.remove('highlighted-target');
        setCurrentStep(currentStep - 1);
      }  
    };


 
    const highlightTarget = () => {
      // Remove 'highlighted' class from the previous target
      if (currentStep > 0) {
        const index = document.getElementById(tourSteps[currentStep - 1].index);
          const previousTarget = document.querySelector(tourSteps[currentStep - 1].targetSelector);
        const prevTarget = document.querySelector(tourSteps[currentStep - 1].target);
        previousTarget?.classList.remove('highlighted');
        prevTarget?.classList.remove('highlighted-target');
        index?.classList.remove('index-target');
      }
      if(currentStep >= 2){
        const menubar = document.getElementById('menubar');
      menubar?.classList.add('menubar-style');
      }
      const currentTarget = document.querySelector(tourSteps[currentStep]?.targetSelector);
      const Target = document.querySelector(tourSteps[currentStep]?.target);
      const index = document.getElementById(tourSteps[currentStep]?.index);
      // Add 'highlighted' class to the current target
      index?.classList.add('index-target')
      currentTarget?.classList.add('highlighted');
      Target?.classList.add('highlighted-target');
    };



    function Skip (){
      localStorage.setItem('tour' , true )
      setAskopen(!Askopen);
       setTour(true)
     }
 
  return (
    <>
 <AskForTour Skip={Skip} setTour={setTour} setState={setAskopen} setStep={setCurrentStep}  State={Askopen}/>
 {
 currentStep >= 0 && 
 <div className='text-white w-full h-full fixed top-0 justify-center items-center flex right-0 z-50 bg-black bg-opacity-[80%] '> 
      {tourSteps.map((step, index) => (
        <div className='' key={step.id} style={{ display: index === currentStep ? 'block' : 'none' }}>
          <div className=  {`transform   translate-x-[50%] absolute  ${  step?.possition  }   right-[50%]  w-full text-center flex flex-col items-center gap-3 custom-tooltip`} >
          <h1 className='  text-[2rem] font-bold '>{step.text} </h1>
            <p className=' w-[70%]  text-[1.1rem] font-[600] '> {step.P}</p>
          </div>
            <div className=  {`absolute  transform  translate-x-[50%] right-[50%] bottom-[20%] z-30   ${currentStep == 0 ? " justify-end" :" justify-between"  }  w-[80%] flex flex-row`} >
              { currentStep > 0 &&  <button className=' w-10 h-10 bg-white  rounded-full text-black ' onClick={prevStep}> <SkipPreviousIcon/> </button>}
            <button className=' w-10 h-10 bg-white  rounded-full text-black ' onClick={nextStep}> <SkipPreviousIcon className=' rotate-180'/></button> 
          </div>
          
        </div>
      ))}
     {!Askopen && <span onClick={Skip} className=' -z-10  absolute top-2 left-2 text-gray-200'>Skip</span>}
      </div>
 }

    </>
  )
}

export default TakeTour