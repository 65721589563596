import {React , useContext} from 'react'
import { themecontext } from "../../context/Themecontexte";
import { useTranslation } from "react-i18next";

function Handedness({OpenHande ,setOpenHande}) {
  const { theme } = useContext(themecontext);
   const {t , i18n} = useTranslation()
  var isArabic = i18n.language.startsWith('ar');
    

  return (
    <div
    className={` duration-200  ${
      !OpenHande ? "top-[50%]" : " top-[-100%] "
    }  flex  z-[120] fixed h-full w-full relateve `}
  >
    <div
      onClick={() => setOpenHande(!OpenHande)}
      className={`  flex  fixed h-full w-full   ${
        !OpenHande ? " opacity-50 top-0  " : "opacity-0  top-[-100%]   "
      }  right-0 bg-black `}
    ></div>

    <div
      className={` ${!theme ?' bg-[#212121]' :' bg-white' }  ${
        OpenHande ? "  -bottom-[100%]  " : "   bottom-[3%]  "
      }   duration-200    gap-[2rem]  transform  px-[1.2rem]  rounded-md    translate-x-[-50%]  md:h-[60%]  h-[40%]         left-[50%] flex fixed lg:w-[66%]   w-[90%]       flex-col  items-center  justify-center py-6    `}
    >
      <span
        onClick={() => setOpenHande(!OpenHande)}
        className=" absolute top-3 right-3 "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 17 17"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.67579 0.378785C6.76965 0.0358478 9.89193 0.0358478 12.9858 0.378785C14.6988 0.570785 16.0808 1.91978 16.2818 3.63878C16.6487 6.77643 16.6487 9.94614 16.2818 13.0838C16.1801 13.9191 15.7992 14.6955 15.201 15.2872C14.6027 15.8789 13.8221 16.2513 12.9858 16.3438C9.89199 16.6878 6.76959 16.6878 3.67579 16.3438C2.83944 16.2513 2.05887 15.8789 1.46061 15.2872C0.86236 14.6955 0.481503 13.9191 0.379791 13.0838C0.0128885 9.94648 0.0128885 6.77709 0.379791 3.63978C0.481459 2.80465 0.862171 2.02833 1.46022 1.43663C2.05828 0.844929 2.83862 0.472529 3.67479 0.379785L3.67579 0.378785ZM4.80079 4.83178C4.94142 4.69133 5.13204 4.61244 5.33079 4.61244C5.52954 4.61244 5.72017 4.69133 5.86079 4.83178L8.33079 7.30178L10.8008 4.83178C10.8695 4.7581 10.9523 4.699 11.0443 4.658C11.1363 4.61701 11.2356 4.59497 11.3363 4.59319C11.437 4.59142 11.537 4.60994 11.6304 4.64766C11.7238 4.68538 11.8086 4.74153 11.8798 4.81275C11.951 4.88397 12.0072 4.9688 12.0449 5.06219C12.0826 5.15557 12.1012 5.2556 12.0994 5.35631C12.0976 5.45701 12.0756 5.55632 12.0346 5.64832C11.9936 5.74032 11.9345 5.82312 11.8608 5.89178L9.39079 8.36178L11.8608 10.8318C11.9933 10.974 12.0654 11.162 12.062 11.3563C12.0585 11.5506 11.9798 11.736 11.8424 11.8734C11.705 12.0108 11.5196 12.0895 11.3253 12.093C11.131 12.0964 10.943 12.0243 10.8008 11.8918L8.33079 9.42179L5.86079 11.8918C5.79213 11.9655 5.70933 12.0246 5.61733 12.0656C5.52533 12.1066 5.42602 12.1286 5.32531 12.1304C5.22461 12.1322 5.12458 12.1136 5.03119 12.0759C4.93781 12.0382 4.85297 11.982 4.78175 11.9108C4.71053 11.8396 4.65439 11.7548 4.61667 11.6614C4.57895 11.568 4.56042 11.468 4.5622 11.3673C4.56398 11.2666 4.58602 11.1672 4.62701 11.0752C4.668 10.9832 4.72711 10.9004 4.80079 10.8318L7.27079 8.36178L4.80079 5.89178C4.66034 5.75116 4.58145 5.56053 4.58145 5.36178C4.58145 5.16303 4.66034 4.97241 4.80079 4.83178Z"
            fill="#E3E3E7"
          />
        </svg>
      </span>
      <div className=" flex flex-col gap-3 text-center">
        <h1 className=" text-[1.2rem]   font-[600] ">{t('profile.Handed')}</h1>
        <p className=" text-[1rem] text-sec "> {t('profile.Handedp')} </p>
      </div>

      <div className=" w-full flex flex-col gap-[.9rem] ">
        <button
          onClick={() => {
            setOpenHande(!OpenHande);
          }}
          className=  {`${isArabic ? ' text-right ': ' text-left '} text-white font-[600] text-[1.1rem] px-[1.1rem] w-full h-[4rem] rounded-[.30rem] bg-main `} 
        >
        {t('profile.RHand')}
        </button>
        <button
          onClick={() => {
            setOpenHande(!OpenHande);
          }}
          className= {` ${isArabic ? ' text-right ': ' text-left '} text-gray-300 font-[600] text-[1.1rem] px-[1.1rem] w-full h-[4rem] rounded-[.30rem]  border-gray-300  border-solid border-[1px] `} 
        >
        {t('profile.LHand')}
        </button>
      </div>
    </div>
  </div>
  )
}

export default Handedness