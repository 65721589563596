// Importing necessary modules and components
import React, { useEffect, useState, useContext } from "react";
import Cookies from "js-cookie";
import SocialLogBtn from "../compenent/Signin/SocialLogBtn";
import TopBar from "../compenent/TopBar";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import generateSigninSchema from "../Validation/SigninVal";
import RestPass from "../compenent/Signin/RestPass";
import { Usercontext } from "../context/Usercontexte";
import LoadingBtn from "../compenent/LoadingBtn";
import { useTranslation } from "react-i18next";
import { clearDataAndLogout } from "../Functions/Logout";
import Masseege from "../compenent/masseege";
import CodeVer from "../compenent/Signin/CodeVer";
import UpdatePassword from "../compenent/Signin/UpdatePassword";
import { sendFcmT } from "../API/SendFcmT";
import { Receiptscontext } from "../context/Receiptscontext";
import { CardsContext } from "../context/Cardscontext";

// Signin Component
function Signin() {
  // State variables
  const [PassVis, setPassVis] = useState(true);
  const [isLoading, setIsLoadings] = useState(false);
  const [msg, setMsg] = useState({ text: "", color: "" });
  const { t, i18n } = useTranslation();
  var isArabic = i18n.language.startsWith("ar");

  // Contexts
  const { setFirstFetch, setUser, User } = useContext(Usercontext);
  const { setCard } = useContext(CardsContext);
  const { setIsLoading, setReceiptes } = useContext(Receiptscontext);
  const SigninSchema = generateSigninSchema();
    
  // Other hooks
  const { pathname } = useLocation();
  const Navto = useNavigate();

  // Check if there is a User, redirect to home
  useEffect(() => {
    if (User && User?.is_anonymous == false) {
      Navto("/");
    }
  }, [User]);

  // Form validation using useFormik
  const { values, handleBlur, errors, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema: SigninSchema,
      onSubmit,
    });


  // updating the lang of the Schema
  useEffect(() => {
    const updatedSchema = generateSigninSchema();
  }, [i18n.language]);

  // Login Function
  function onSubmit() {
    clearDataAndLogout(setCard, setReceiptes, setUser, setFirstFetch);
    setIsLoadings(true);
    setIsLoading(true);

    axios
      .post(
        "/api/clients/login",
        {
          email: values.email,
          password: values.password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const { data } = response;

        // Save the access_token in Cookies
        Cookies.set("access_token", "Token " + data.access_token);

        // Set User and perform necessary actions
        setUser(data.user);
        setIsLoadings(false);

        // Send FCM Token
        sendFcmT("Token " + data.access_token);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoadings(false);

        // Handle different error cases
        if (error?.response?.data?.non_field_errors) {
          setMsg({
            text: t("Msg.InvalidCredentials"),
            color: "bg-[#FF1203F5] text-[0.9rem] ",
          });
        } else {
          setMsg({
            text: t("Msg.InternalServerError"),
            color: "bg-[#FF1203F5] text-[0.9rem] ",
          });
        }
      });
  }

  return (
    <div className=" pb-24">
      {pathname === "/Signin" && (
        <>
          {/* TopBar component */}
          <div className="  w-full h-full  px-[1.2rem] pt-[1.8rem] ">
            <TopBar text={t("topbar.Signin")} />
          </div>

          {/* Signin form */}
          <form
            onSubmit={handleSubmit}
            className=" flex flex-col  px-[1.4rem] pt-[2.4rem]   "
          >
            {/* Welcome message */}
            <div className=" flex flex-col items-center pb-[1.7rem]  ">
              <h1 className=" text-[1.8rem] font-[700] ">
                {t("Signin.welcom")}
              </h1>
              <p className=" text-sec text-sm">{t("Signin.getStarted")}</p>
            </div>

            {/* Social Login Buttons */}
            <SocialLogBtn Loginpage={true} From={t('topbar.Signin')} />

            {/* OR Divider */}
            <div className=" w-full flex flex-row text-gray-400 gap-2 items-center justify-center pt-[1.2rem] ">
              <span className=" w-full h-[1px] bg-gray-200  "></span>{" "}
              {t("profile.OR")}
              <span className=" w-full h-[1px] bg-gray-200  "></span>
            </div>

            {/* Email input */}
            <div className=" flex flex-col   ">
              <h1 className="pb-2   pt-[1rem] text-[.9rem] font-medium  ">
                {t("Signup.Email")}
              </h1>
              <input
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                placeholder="example@gmail.com"
                className={`  ${isArabic ? " text-right" : " text-left"}   ${
                  errors.email && touched.email
                    ? " border-[#fc8181] text-red-500  border-[2px]"
                    : ""
                }   text-sec w-full h-[3.5rem]  bg-transparent  px-[1rem]   text-sm  border-solid border-[1px] rounded-[0.32rem]`}
                type="Email"
                name=""
                id="email"
              />
              {errors.email && touched.email && (
                <p className=" pt-1 px-1 text-xs text-red-400">
                  {errors.email}
                </p>
              )}
            </div>

            {/* Password input */}
            <div className=" flex flex-col gap-2 ">
              <h1 className="    pt-[1rem]  font-medium text-[.9rem] ">
                {t("Signup.psw")}
              </h1>
              <span className=" relative ">
                <input
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  className={`  ${isArabic ? " text-right" : " text-left"}   ${
                    errors.password && touched.password
                      ? " border-[#fc8181] text-red-500  border-[2px]"
                      : ""
                  }  text-sec w-full  h-[3.5rem] bg-transparent   px-[1rem]   text-sm  border-solid border-[1px] rounded-[0.32rem]`}
                  type={PassVis ? "password" : "text"}
                  placeholder={t("Signup.writePsw")}
                  name=""
                  id="password"
                />
                {errors.password && touched.password && (
                  <p className=" pt-1 px-1 text-xs text-red-400">
                    {errors.password}
                  </p>
                )}
                <span
                  onClick={() => setPassVis(!PassVis)}
                  className={`absolute  ${
                    isArabic ? "  left-4" : " right-4"
                  }  top-4 `}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 17 12"
                    fill="none"
                  >
                    <path
                      d="M8.63413 0.0593017C4.99778 0.0496582 1.88628 2.32208 0.619569 5.53807C1.86921 8.76073 4.9686 11.0496 8.60496 11.0593C12.2413 11.0689 15.3528 8.79648 16.6195 5.5805C15.3699 2.35784 12.2705 0.0689453 8.63413 0.0593017ZM8.60982 9.22594C6.60255 9.22061 4.97782 7.57363 4.98319 5.54964C4.98856 3.52565 6.622 1.88731 8.62927 1.89263C10.6365 1.89795 12.2613 3.54493 12.2559 5.56893C12.2505 7.59292 10.6171 9.23126 8.60982 9.22594ZM8.62538 3.35929C7.41811 3.35609 6.44096 4.33617 6.43773 5.5535C6.4345 6.77083 7.40644 7.75607 8.61371 7.75927C9.82098 7.76248 10.7981 6.7824 10.8014 5.56507C10.8046 4.34774 9.83264 3.36249 8.62538 3.35929Z"
                      fill={!PassVis ? "#06C671" : "#CBCBCB"}
                    />
                  </svg>
                </span>
              </span>

              {/* Forgot Password link */}
              <Link to={"/Signin/restpassword"} className=" text-sm text-sec">
                {t("Signin.Fpsw")}
              </Link>
            </div>

            {/* Signin button */}
            <div className=" w-full pt-5 ">
              <button
                type="submit"
                className=" h-[3.4rem] relative  text-[1.1rem] font-medium text-white w-full bg-main rounded-[0.32rem] "
              >
                {t("topbar.Signin")}
                <LoadingBtn
                  color={"#fff"}
                  isLoading={isLoading}
                  small={true}
                  bg={"bg-main"}
                />
              </button>
            </div>
          </form>
        </>
      )}

      {/* Message component */}
      <Masseege msg={msg} setMsg={setMsg} />

      {/* Conditional rendering based on pathname */}
      {pathname === "/Signin/restpassword" && (
        <RestPass msg={msg} setMsg={setMsg} />
      )}
      {pathname === "/Signin/codeverification" && (
        <CodeVer msg={msg} setMsg={setMsg} />
      )}
      {pathname === "/Signin/UpdatePassword" && (
        <UpdatePassword msg={msg} setMsg={setMsg} />
      )}
    </div>
  );
}

// Exporting the Signin component
export default Signin;
