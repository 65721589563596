// Importing necessary modules and dependencies
import React, { useState, useContext, useEffect, useRef } from "react";
import TopBar from "../compenent/TopBar";
import AddIcon from "@mui/icons-material/Add";
import { Sdata } from "../assest/data/Sdata";
import { Swiper, SwiperSlide } from "swiper/react";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useLocation } from "react-router-dom";
import "swiper/swiper.min.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import AddCard from "../compenent/Crad/AddCard";
import AddCardManully from "../compenent/Crad/AddCardManully";
import CardDetails from "../compenent/Crad/CardDetails";
import NoCards from "../compenent/Crad/NoCards";
import SearchIcon from "@mui/icons-material/Search";
import { Usercontext } from "../context/Usercontexte";
import Card from "../compenent/Crad/Card";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import Story from "../compenent/Crad/Story";
import { CardsContext } from "../context/Cardscontext";

// Cards Component
function Cards() {
  // Getting the current pathname from react-router
  const { pathname } = useLocation();

  // State variables
  const [SelectedStory, setSelectedStory] = useState();
  const [SearchIsOpen, setSearchIsOpen] = useState(false);
  const [StoryOpen, setStoryOpen] = useState(false);
  const { User } = useContext(Usercontext);
  const { card, setIsCardChange, IsCardChange } = useContext(CardsContext);
  const { t, i18n } = useTranslation();
  const [FilterCard, setFilterCard] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  var isArabic = i18n.language.startsWith("ar");
  const searchRef = useRef();

  // Effect to initialize FilterCard with the value of card when it changes
  useEffect(() => {
    setFilterCard(card);
  }, [card]);

  // Function to handle search based on store_name
  const handleSearch = () => {
    const cards = card?.filter((item) =>
      item.store_name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );
    setFilterCard(cards);
  };

  // Effect to handle search when searchTerm changes
  useEffect(() => {
    handleSearch();
  }, [searchTerm]);

  // Event handler for input change
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    handleSearch();
  };

  // Function to get the story based on ID
  function getStory(id) {
    setSelectedStory(Sdata.find((i) => i.id == id));
  }

  // Animation configuration
  const container = {
    hidden: { opacity: 1, scale: 1 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        delayChildren: 0.2,
        staggerChildren: 0.2,
      },
    },
  };

  // Breakpoints for Swiper component
  const breakpoints = {
    320: {
      slidesPerView: 5,
    },
    480: {
      slidesPerView: 7,
    },
  };

  // JSX for rendering the component
  return (
    <>
      {pathname == "/Cards" && (
        <div className="   w-full   flex  flex-col  px-[1.2rem]    relative    ">
          <div className=" w-full pt-[1.4rem]  flex flex-row justify-between">
            <TopBar text={t("topbar.Cardtoptitle")} />
          </div>
          {StoryOpen && (
            <Story stories={SelectedStory} setStoryOpen={setStoryOpen} />
          )}

          <Swiper
            breakpoints={breakpoints}
            centeredSlides={false}
            spaceBetween={20}
            grabCursor={true}
            className="mySwiper   flex-row w-full h-full     items-center justify-center   flex  mt-[1.8rem]  "
          >
            {Sdata.map((item) => (
              <SwiperSlide>
                <div
                  onClick={() => {
                    getStory(item.id);
                    setStoryOpen(true);
                  }}
                  className={` ${
                    item.new ? "border-green-400 border-[1.3px] " : ""
                  }   relative border-solid w-[4.2rem] h-[4.2rem]  rounded-[50%] border-[1px]`}
                >
                  <img
                    className={` rounded-[50%]  absolute top-0 right-0 w-full h-full object-cover    p-[0.15rem]   z-30`}
                    src={item.img}
                    alt=""
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {card?.length <= 0 || !User ? (
            <NoCards />
          ) : (
            <div>
              <div className=" pt-2"></div>
              <div className=" gap-1 w-full flex  flex-col pt-[1.3rem] ">
                <h1 className=" text-[1.1rem] font-[600]  ">
                  {" "}
                  {t("card.title")}{" "}
                </h1>
                <p className=" pb-[.9rem] text-sm text-[500] text-sec ">
                  {t("card.p", { num: card?.length })}{" "}
                </p>
                <motion.div
                  variants={container}
                  initial="hidden"
                  animate="visible"
                  className=" grid grid-cols-2  h-full gap-2"
                >
                  {card?.map((i) => (
                    <Card
                      Sname={i?.store_name}
                      id={i?.id}
                      img={i?.front_image}
                    />
                  ))}
                </motion.div>
              </div>
            </div>
          )}
          <Link
            to={"/Cards/addcart"}
            className="   p-3 px-4  fixed   bottom-[10%] flex flex-row gap-2 items-center justify-center  right-5 bg-main  text-sm text-white rounded-lg "
          >
            <AddIcon /> {t("card.addC")}
          </Link>
        </div>
      )}
      {pathname == "/Cards/addcartmanully" && (
        <AddCardManully
          IsCardChange={IsCardChange}
          setIsCardChange={setIsCardChange}
        />
      )}
      {pathname == "/Cards/addcart" && <AddCard />}
      {pathname == "/Cards/CardDetails" && <CardDetails />}
    </>
  );
}

// Exporting the Cards component
export default Cards;
