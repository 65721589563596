import { React, useEffect, useState, useRef, useContext } from "react";
import LoadingBtn from "../LoadingBtn";
import { useTranslation } from "react-i18next";

function EditReceipt({ id, setIsOpen, isLoading, handleEdit, FiltredData }) {
  const [ReceiptName, setReceiptName] = useState(null);
  const inputRef = useRef(null);
  const { t ,i18n } = useTranslation();
  var isArabic = i18n.language.startsWith("ar");

  useEffect(() => {
    if (!id) return;
    const getName = FiltredData?.find((item) => item.id == id);
    console.log(getName?.name);
    setReceiptName(getName?.name);
    inputRef.current.focus();
  }, [setIsOpen, id]);

  return (
    <div className=" flex flex-col gap-5 items-center justify-center w-full  pt-7  ">
      <h1 className=" font-bold "> {t("receipts.customReceiptName")} </h1>
      <input
        ref={inputRef}
        value={ReceiptName}
        onChange={(e) => setReceiptName(e.target.value)}
        placeholder={t("receipts.customReceiptNamePlaceholder")}
        className=  {` ${isArabic ? ' text-right' :' text-left' } bg-transparent px-6 w-full h-[4rem] border-solid border-[1px] rounded-md`} 
        type="text"
      />

      <div className=" flex  w-full font-semibold text-[#939393] text-[1rem] flex-row gap-2 ">
        <button
          onClick={() => setIsOpen(null)}
          className=" w-full h-[3rem]  bg-[#E4E4E4]   rounded-md  "
        >
          {t("card.Cancel")}
        </button>
        <button
          onClick={() => {
            handleEdit(id, ReceiptName);
          }}
          className=" relative text-white w-full h-[3rem] bg-main rounded-md  "
        >
          {t("receipts.confirmModify")}

          <LoadingBtn isLoading={isLoading} color={"white"} bg={"bg-main"} />
        </button>
      </div>
    </div>
  );
}

export default EditReceipt;
