// chartOptions.js

export const SpendingChartOptions = (state, theme, HchartData, LchartData, chartData, AChartData) => {
    return {
        chart: {
            animations: {
              enabled: true, // Enable animations
              dynamicAnimation: {
                speed: 50, // Animation speed in milliseconds
              },
            },
            foreColor: theme ? "#333" : "#ffefef",
            fontFamily: "Archivo",
            height: 258,
            id: "Iz30P",
            toolbar: {
              show: false,
            },
            type: "area",
            width: 480,
            fontUrl: null,
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
              borderRadiusApplication: "end",
              borderRadiusWhenStacked: "last",
              hideZeroBarsWhenGrouped: false,
              isDumbbell: false,
              isFunnel: false,
              isFunnel3d: true,
              dataLabels: {
                total: {
                  enabled: false,
                  offsetX: 0,
                  offsetY: 0,
                  style: {
                    color: "#373d3f",
                    fontSize: "12px",
                    fontWeight: 600,
                  },
                },
              },
            },
            bubble: {
              zScaling: true,
            },
            treemap: {
              dataLabels: {
                format: "scale",
              },
            },
            radialBar: {
              hollow: {
                background: "#fff",
              },
              dataLabels: {
                name: {},
                value: {},
                total: {},
              },
            },
            pie: {
              donut: {
                labels: {
                  name: {},
                  value: {},
                  total: {},
                },
              },
            },
          },
      
          dataLabels: {
            enabled: false,
            offsetX: -7,
            offsetY: -4,
            style: {
              fontWeight: 400,
            },
            dropShadow: {
              blur: 2,
            },
          },
          fill: {},
          grid: {
            show: false,
            strokeDashArray: 8,
            xaxis: {
              lines: {
                show: true,
              },
            },
            column: {},
            padding: {
              top: -13,
              right: 0,
              left: -13,
            },
          },
          legend: {
            show: false,
            showForSingleSeries: true,
            floating: true,
            fontSize: 14,
            offsetY: 0,
            markers: {
              width: 29,
              height: 30,
              strokeWidth: 10,
              radius: 49,
            },
            itemMargin: {
              vertical: 0,
            },
          },
          markers: {
            size: 6,
            strokeColors: "#40FF26",
            strokeWidth: 0,
            fillOpacity: 0.7,
            hover: {
              size: 4,
              sizeOffset: 6,
            },
          },
          series:
            state == 2 || state == 3
              ? [
                  {
                    name: "Area",
                    data: HchartData,
                    color: "#fc6464", // Color for series 1
                    type: state === 2 ? "area" : "line",
                  },
                  {
                    name: "series-2",
                    data: LchartData,
                    color: "#00D979", // Color for series 2
                    type: state === 3 ? "area" : "line",
                  },
                ]
              : state == 0
              ? [
                  {
                    name: "Area",
                    data: chartData,
                    color: "#00D979", // Color for series 1
                    type: state === 0 ? "area" : "line",
                  },
                ]
              : [
                  {
                    name: "Area",
                    data: AChartData,
                    color: "#ebc041", // Color for series 1
                    type: state === 1 ? "area" : "line",
                  },
                ],
      
          stroke: {
            lineCap: "round",
      
            width: 4,
            fill: {
              type: "solid",
              opacity: 0.85,
              gradient: {
                shade: "light",
                type: "vertical",
                shadeIntensity: 0.5,
                inverseColors: false,
                opacityFrom: 0.65,
                opacityTo: 0.5,
                stops: [0, 100, 100],
                colorStops: [],
              },
            },
          },
          xaxis: {
            type: "numeric",
            offsetY: -5,
            labels: {
              trim: true,
              style: {
                fontWeight: 200,
              },
            },
            group: {
              groups: [],
              style: {
                colors: [],
                fontSize: "12px",
                fontWeight: 400,
                cssClass: "",
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            tickAmount: "dataPoints",
            title: {
              style: {
                fontWeight: 700,
              },
            },
          },
          yaxis: {
            offsetY: 10,
            tickAmount: 6,
            labels: {
              style: {
                colors: [null, null, null, null, null, null, null],
                fontSize: 10,
                fontWeight: 200,
              },
            },
            title: {
              style: {},
            },
          },
    };
  };
 


  // chartOptions.js

export const BillingChartOptions = ( state, theme, PDatachart, UPDatachart, RDatachart, PPDatachart,) => {
    return {
        chart: {
            animations: {
              enabled: true, // Enable animations
              dynamicAnimation: {
                speed: 50, // Animation speed in milliseconds
              },
            },
            foreColor: theme ? "#333" : "#ffefef",
            fontFamily: "Archivo",
            height: 258,
            id: "Iz30P",
            toolbar: {
              show: false,
            },
            type: "area",
            width: 480,
            fontUrl: null,
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
              borderRadiusApplication: "end",
              borderRadiusWhenStacked: "last",
              hideZeroBarsWhenGrouped: false,
              isDumbbell: false,
              isFunnel: false,
              isFunnel3d: true,
              dataLabels: {
                total: {
                  enabled: false,
                  offsetX: 0,
                  offsetY: 0,
                  style: {
                    color: "#373d3f",
                    fontSize: "12px",
                    fontWeight: 600,
                  },
                },
              },
            },
            bubble: {
              zScaling: true,
            },
            treemap: {
              dataLabels: {
                format: "scale",
              },
            },
            radialBar: {
              hollow: {
                background: "#fff",
              },
              dataLabels: {
                name: {},
                value: {},
                total: {},
              },
            },
            pie: {
              donut: {
                labels: {
                  name: {},
                  value: {},
                  total: {},
                },
              },
            },
          },
      
          dataLabels: {
            enabled: false,
            offsetX: -7,
            offsetY: -4,
            style: {
              fontWeight: 400,
            },
            dropShadow: {
              blur: 2,
            },
          },
          fill: {},
          grid: {
            show: false,
            strokeDashArray: 8,
            xaxis: {
              lines: {
                show: true,
              },
            },
            column: {},
            padding: {
              top: -13,
              right: 0,
              left: -13,
            },
          },
          legend: {
            show: false,
            showForSingleSeries: true,
            floating: true,
            fontSize: 14,
            offsetY: 0,
            markers: {
              width: 20,
              height: 20,
              strokeWidth: 5,
              radius: 49,
            },
            itemMargin: {
              vertical: 0,
            },
          },
          markers: {
            size: 6,
            strokeColors: "#40FF26",
            strokeWidth: 0,
            fillOpacity: 0.7,
            hover: {
              size: 4,
              sizeOffset: 6,
            },
          },
          series:
            state == 0
              ? [
                  {
                    name: "Area",
                    data: PDatachart,
                    color: "#00D979", // Color for series 1
                  },
                ]
              : state == 1
              ? [
                  {
                    name: "Area",
                    data: UPDatachart,
                    color: "#F42929", // Color for series 1
                  },
                ]
              : state == 2
              ? [
                  {
                    name: "Area",
                    data: PPDatachart,
                    color: "#FF8125", // Color for series 1
                  },
                ]
              : [
                  {
                    name: "Area",
                    data: RDatachart,
                    color: "#29D7FD", // Color for series 1
                  },
                ],
      
          stroke: {
            lineCap: "round",
            width: 4,
            fill: {
              type: "solid",
              opacity: 0.85,
              gradient: {
                shade: "light",
                type: "vertical",
                shadeIntensity: 0.5,
                inverseColors: false,
                opacityFrom: 0.65,
                opacityTo: 0.5,
                stops: [0, 100, 100],
                colorStops: [],
              },
            },
          },
          xaxis: {
            type: "numeric",
            offsetY: -5,
            labels: {
              trim: true,
              style: {
                fontWeight: 200,
              },
            },
            group: {
              groups: [],
              style: {
                colors: [],
                fontSize: "12px",
                fontWeight: 400,
                cssClass: "",
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            tickAmount: "dataPoints",
            title: {
              style: {
                fontWeight: 700,
              },
            },
          },
          yaxis: {
            offsetY: 10,
            tickAmount: 6,
            labels: {
              style: {
                colors: [null, null, null, null, null, null, null],
                fontSize: 10,
                fontWeight: 200,
              },
            },
            title: {
              style: {},
            },
          },
  };
   
}


export const LinedChartOption = ( state,
  theme,
  colors,
  PhysicalChartD,
  DigitalChartD,
  TrendsChart,
  EmpactChartD
   ) => {
  return {
    animations: {
      enabled: true, // Enable animations
      dynamicAnimation: {
        speed: 50, // Animation speed in milliseconds
      },
    },
    chart: {
      animations: {
        enabled: false,
      },
      foreColor: theme ? "#333" : "#ffefef",

      fontFamily: "Archivo",
      height: 258,
      id: "Iz30P",
      toolbar: {
        show: false,
      },
      type: "area",
      width: 480,
      fontUrl: null,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        borderRadiusApplication: "end",
      },
      bubble: {
        zScaling: true,
        
      },
      treemap: {
        dataLabels: {
          format: "scale",
        },
      },
      radialBar: {
        hollow: {
          background: "#fff",
        },
      },
      
    
    },
    colors: ["#00D979", "#00E396", "#FEB019", "#FF4560", "#775DD0"],
    dataLabels: {
      enabled: false,
      offsetX: -7,
      offsetY: -5,
      style: {
        fontWeight: 400,
      },
      dropShadow: {
        blur: 2,
      },
    },
    fill: {},
    grid: {
      strokeDashArray: 4,
      xaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: -11,
        right: 0,
        left: -10,
      },
    },
    legend: {
      show: false,
      showForSingleSeries: true,
      floating: true,
      fontSize: 14,
      offsetY: 0,
      markers: {
        width: 29,
        height: 30,
        strokeWidth: 10,
        radius: 49,
      },
      itemMargin: {
        vertical: 0,
      },
    },
    markers: {
      size: 6,
      strokeColors: "#40FF26",
      strokeWidth: 0,
      fillOpacity: 0.7,
      hover: {
        size: 4,
        sizeOffset: 6,
      },
       
    },
    series: state == 'ReceiptType'
      ? [
          {
            name: "Area",
            data: PhysicalChartD,
            color: colors[0],  
          },
          {
            name: "Area",
            data: DigitalChartD,
            color: colors[1],  
          },
        ]
      
       : state == 'Eimpact' ? 
       [
        {
          name: "Area",
          data: EmpactChartD,
          color: colors[0],  
        },
        {
          name: "Area",
          data: DigitalChartD,
          color: colors[1],  
        },
      ]
        : [
          {
            name: "Area",
            data: TrendsChart,
            color: "#ebc041",  
          },
        ],

    stroke: {
      lineCap: "round",
      width: 4,
      curve: "smooth",
      fill: {
        type: "solid",
        opacity: 0.85,
        gradient: {
          shade: "light",
          type: "vertical",
          shadeIntensity: 0.5,
          inverseColors: false,
          opacityFrom: 0.65,
          opacityTo: 0.5,
          stops: [0, 100, 100],
          colorStops: [],
        },
      },
    },
    xaxis: {
      type: "numeric",
      offsetY: -5,
      labels: {
        trim: true,
        style: {
          fontWeight: 300,
        },
      },
      
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tickAmount: "dataPoints",
      title: {
        style: {
          fontWeight: 700,
        },
      },
    },
    yaxis: {
      offsetY: 10,
      tickAmount: 6,
      labels: {
        style: {
          fontSize: 10,
          fontWeight: 300,
        },
      },
    },
     }    
}
