import { React, useState } from "react";
import Masseege from "../../compenent/masseege";
import Uuser from "../../assest/Uuser.svg";
import EditIcon from "@mui/icons-material/Edit";
import LoadingBtn from "../../compenent/LoadingBtn";
import { useTranslation } from "react-i18next";
import { updateProfilePictureApi } from "../../API/UserAPI";

function UserExist({ User, setUser, update, setUpdate }) {
  const [msg, setMsg] = useState({ text: "", color: "" });
  const [IsLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  // Selecting the img for uploading
  async function handleFileChange(e) {
    const selectedFile = e.target.files[0];
    setIsLoading(true);
    const fileSizeInMB = selectedFile.size / (1024 * 1024); // Convert bytes to megabytes
    // Check if the file size is less than 2MB
    if (fileSizeInMB >= 5) {
      setMsg({
        text: t("Msg.FileSizeExceed"),
        color: "bg-[#FF1203F5] text-[0.9rem]",
      });
      return;
    }
    try {
      const userData = await updateProfilePictureApi(selectedFile);
      setUser(userData);
      console.log('userData')
      console.log(userData)

      setMsg({
        text: t("Msg.PictureUpdateSuccess"),
        color: "bg-[#4fd837]",
      });
    //   setUpdate(!update);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } catch (error) {
      setMsg({
        text: t("Msg.PictureUpdateError"),
        color: "bg-[#FF1203F5]",
      });
      setIsLoading(false);
    }
  }

  return (
    <div className="    absolute top-0   right-0  w-full bg-main h-[120px] ">
      <Masseege setMsg={setMsg} msg={msg} />
      <div className=" flex flex-col items-center   transform translate-x-[50%] right-1/2 absolute -bottom-20  ">
        <span className="    relative w-[120px] rounded-full  h-[120px] bg-white shadow-lg  ">
          {IsLoading && (
            <span className=" absolute top-0 right-0 rounded-full  w-full h-full bg-white  bg-opacity-20">
              <LoadingBtn color={"#000"} isLoading={IsLoading} />
            </span>
          )}
          <img
            src={User?.profile_picture != null ? User?.profile_picture : Uuser}
            className="  rounded-full  bg-cover object-cover w-full h-full"
            alt=""
          />
          <span className=" absolute  -bottom-2 right-3 p-2 bg-[#F3F3F3] rounded-full ">
            <label
              htmlFor="fileupload"
              className="      border-solid border-[1px] relative overflow-hidden rounded-md items-center justify-center gap-2 flex flex-col "
            />
            <input
              type="file"
              accept="image/*" // Specify accepted file types if needed
              onChange={handleFileChange}
              multiple={false}
              style={{ display: "none" }}
              id="fileupload"
            />
            <EditIcon className=" text-gray-500 " />
          </span>
        </span>
        <h1 className=" mt-5 text-[#1D1A4As] font-[600] text-[1.3rem] ">
          {" "}
          {User ? User?.first_name + " " + User?.last_name : "unknown"}{" "}
        </h1>
      </div>
    </div>
  );
}

export default UserExist;
