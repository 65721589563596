// Importing necessary modules and dependencies
import { React, useState, useContext, useEffect } from "react";
import TopBar from "../../compenent/TopBar";
import CloseBtn from "../../assest/CloseBtn.svg";
import generateAddCardSchema from "../../Validation/AddCardVal";
import { useFormik } from "formik";
import LoadingBtn from "../../compenent/LoadingBtn";
import Masseege from "../../compenent/masseege";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getCodeBarFromPic } from "../../Functions/getCodeBarFromPic";
import { CardsContext } from "../../context/Cardscontext";
import AddCardOrUpdate from "../../API/CardAPI";
import { addCardOrUpdateToLocalDB } from "../../DB/CardDB";
import cameraImg from "../../assest/camera.svg";
import useOnlineStatus from "../../hooks/useOnlineStatus";
import { Cropperr } from "../Cropper";
// AddCardManully Component
function AddCardManully() {
  // State variables
  const [frontImage, setFrontImage] = useState(null);
  const [CfrontImage, setCfrontImage] = useState(null);
  const [backImage, setBackImage] = useState(null);
  const [CbackImage, setCbackImage] = useState(null);

  const [msg, setMsg] = useState({ text: "", color: "" });
  const [Code, setCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { setCard, card } = useContext(CardsContext);
  const { t, i18n } = useTranslation();
  let isArabic = i18n.language.startsWith("ar");
  const Navto = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const brand = searchParams.get("brand");
  const isOnline = useOnlineStatus();
  const { id } = useParams();
  const intID = parseInt(id, 10);
  const oneCard = card?.find((item) => item.id == id);

  const AddCardSchema = generateAddCardSchema();

  useEffect(() => {
    const hundelgetCodeBarFromPic = async () => {
      if (CbackImage) {
        try {
          const code = await getCodeBarFromPic(CbackImage);
          setCode(code);
        } catch (error) {}
      }
    };
    hundelgetCodeBarFromPic();
  }, [CbackImage]);

  // Formik form setup
  const {
    setValues,
    values,
    handleBlur,
    errors,
    touched,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      storename: "",
      cardnum: "",
      notes: "",
    },
    validationSchema: AddCardSchema,
    onSubmit,
  });

    // updating the lang of the Schema
    useEffect(() => {
      const updatedSchema = generateAddCardSchema();
    }, [i18n.language]);
  

 
  // Effect to update values when Code changes
  useEffect(() => {
    setValues({
      ...values,
      cardnum: Code,
    });
  }, [Code]);

  useEffect(() => {
    if (!id) return;
    setValues({
      storename: oneCard?.store_name,
      cardnum: oneCard?.code,
      notes: oneCard?.notes,
    });
    setCbackImage(oneCard?.back_image);
    setCfrontImage(oneCard?.front_image);
  }, [card]);

  // File change handler for front image
  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    const fileSizeInMB = selectedFile?.size / (1024 * 1024); // Convert bytes to megabytes

    // Check if the file size is less than 2MB
    if (fileSizeInMB >= 5) {
      setMsg({
        text: t("Msg.FileSizeExceed"),
        color: "bg-[#FF1203F5] text-[0.9rem]",
      });
      return;
    }
    setFrontImage(selectedFile);
    if (!selectedFile) {
      return;
    }
  };

  // File change handler for back image
  const handleFileChange2 = (e) => {
    const selectedFile = e.target.files[0];
    const fileSizeInMB = selectedFile?.size / (1024 * 1024); // Convert bytes to megabytes

    // Check if the file size is less than 2MB
    if (fileSizeInMB >= 5) {
      setMsg({
        text: t("Msg.FileSizeExceed"),
        color: "bg-[#FF1203F5] text-[0.9rem]",
      });
      return;
    }
    setBackImage(selectedFile);
    if (!selectedFile) {
      return;
    }
  };

  // Form submission handler
  async function onSubmit() {
    setIsLoading(true);
    if (!isOnline) {
      function generateUniqueId() {
        return "_" + Math.random().toString(36).substr(2, 9);
      }
      const cardDetails = {
        back_image: CbackImage,
        code: values.cardnum,
        front_image: CfrontImage,
        id: id ? intID : generateUniqueId(),
        notes: values.notes,
        store_name: values.storename,
        opiration: id ? "update" : "add",
        local: true,
      };
      // adding the card to the localDB
      await addCardOrUpdateToLocalDB(cardDetails);
      if (!id) {
        setCard((prevCards) => [cardDetails, ...prevCards]);
      }

      setTimeout(() => {
        setIsLoading(false);
        Navto("/Cards");
      }, 1000);
    } else {
      setIsLoading(true);
      // uploding the card to the server
      // formating the data befor sending it to the server
      const formData = new FormData();
      if (CfrontImage != null) {
        formData.append("front_image", CfrontImage);
      }
      if (CbackImage != null) {
        formData.append("back_image", CbackImage);
      }

      formData.append("code", values.cardnum);
      formData.append("notes", values.notes);
      formData.append("store_name", values.storename);

      try {
        const response = await AddCardOrUpdate(
          id,
          formData,
          id ? "patch" : "post"
        );
        console.log(response);

        // Handle the response according to your requirements
        if (id) {
          addCardOrUpdateToLocalDB(response);
          setMsg({
            text: "Card updated successfully",
            color: "bg-green-400 text-[0.9rem]",
          });
        } else {
          // Add card logic
          setCard((prevCards) => [response, ...prevCards]);
          setMsg({
            text: "Card added successfully",
            color: "bg-green-400 text-[0.9rem]",
          });
        }

        setIsLoading(false);
        setTimeout(() => {
          Navto("/Cards");
        }, 1000);
      } catch (error) {
        // Handle errors
        setIsLoading(false);
      }
    }
  }

  // // Effect to fetch card info from the server
  // useEffect(() => {
  // GetFidelity_card(id, selectedCard, setFrontImage, setBackImage, setValues);
  // }, []);

  // Effect to get already existing card
  useEffect(() => {
    setValues({
      storename: brand,
    });
  }, [brand]);
  // JSX for rendering the component
  return (
    <div className=" px-[1.2rem] pt-[1.8rem] flex flex-col pb-12 ">
      <TopBar text={id ? t("card.EditC") : t("card.addC")} />

      <form className="  py-12" onSubmit={handleSubmit}>
        <div
          className={` w-full flex ${
            isArabic ? "  flex-row-reverse " : "  flex-row"
          } gap-2 justify-center items-center `}
        >
          {/* Front Image Upload */}
          <label className="    h-[7rem] w-[9rem]  border-solid border-[1px] relative overflow-hidden rounded-md items-center justify-center gap-2 flex flex-col ">
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              multiple={false}
              style={{ display: "none" }}
            />

            {CfrontImage && (
              <button
                type="button"
                onClick={(e) => {
                  setCfrontImage("");
                  e.preventDefault();
                }}
              >
                <img
                  className=" absolute top-2 right-2 z-20"
                  src={CloseBtn}
                  alt=""
                />
              </button>
            )}
            {CfrontImage && (
              <img
                src={
                  typeof CfrontImage === "string"
                    ? CfrontImage
                    : URL.createObjectURL(CfrontImage)
                }
                alt="Selected"
                className=" w-full h-full   object-cover absolute top-0 right-0"
              />
            )}

            {!CfrontImage && (
              <span className=" h-[7rem] w-[9rem]  border-solid border-[1px] rounded-md items-center justify-center gap-2 flex flex-col  ">
                <img className=" scale-150 " src={cameraImg} alt="" />
                <h1 className=" text-sm"> {t("card.Front")}</h1>
              </span>
            )}
          </label>

          {/* Back Image Upload */}
          <label className="  h-[7rem] w-[9rem]  border-solid border-[1px] relative overflow-hidden rounded-md items-center justify-center gap-2 flex flex-col ">
            <input
              className=""
              type="file"
              accept="image/*"
              onChange={handleFileChange2}
              multiple={false}
              style={{ display: "none" }}
            />

            {CbackImage && (
              <button
                type="button"
                onClick={(e) => {
                  setCbackImage(null);
                  e.preventDefault();
                }}
              >
                <img
                  className=" absolute top-2 right-2 z-20"
                  src={CloseBtn}
                  alt=""
                />
              </button>
            )}
            {CbackImage && (
              <img
                className=" w-full h-full  object-cover absolute top-0 right-0"
                src={
                  typeof CbackImage === "string"
                    ? CbackImage
                    : URL.createObjectURL(CbackImage)
                }
                alt="Selected"
              />
            )}
            {!CbackImage && (
              <span className=" h-[7rem] w-[9rem]  border-solid border-[1px] rounded-md items-center justify-center gap-2 flex flex-col  ">
                <img className=" scale-150 " src={cameraImg} alt="" />
                <h1 className=" text-sm">{t("card.Back")}</h1>
              </span>
            )}
          </label>
        </div>

        <div id="qr-code-container"></div>

        {/* Store Name Input */}
        <div className=" flex flex-col  ">
          <h1 className=" font-medium  pb-2   pt-[1.5rem] text-[.9rem] ">
            {t("card.Sname")}
          </h1>
          <input
            placeholder={t("card.SnamePlaceh")}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.storename}
            className={`  ${isArabic ? "  text-right " : "  text-left"}  ${
              errors.storename && touched.storename
                ? " border-[#fc8181] text-red-500  border-[2px]"
                : ""
            } text-sec w-full h-[4rem]  bg-transparent    px-[1rem]   text-sm  border-solid border-[1px] rounded-[0.32rem]`}
            type="text"
            name=""
            id="storename"
          />
          {errors.storename && touched.storename && (
            <p className="   pt-1 px-1 text-xs text-red-400">
              {" "}
              {errors.storename}{" "}
            </p>
          )}
        </div>

        {/* Card Number Input */}
        <div className=" flex flex-col  ">
          <h1 className="pb-2  pt-[1.5rem] text-[.9rem] font-medium ">
            {t("card.Cnumber")}
          </h1>
          <input
            placeholder={t("card.CnumberPlach")}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.cardnum}
            className={`  ${isArabic ? "  text-right " : "  text-left"}  ${
              errors.cardnum && touched.cardnum
                ? " border-[#fc8181] text-red-500  border-[2px]"
                : ""
            } text-sec w-full h-[4rem] bg-transparent   px-[1rem]   text-sm  border-solid border-[1px] rounded-[0.32rem]`}
            type="text"
            id="cardnum"
          />
          {errors.cardnum && touched.cardnum && (
            <p className=" pt-1 px-1 text-xs text-red-400">
              {" "}
              {errors.cardnum}{" "}
            </p>
          )}
        </div>

        {/* Notes Input */}
        <div className=" flex flex-col   ">
          <h1 className="pb-2   pt-[1.5rem] text-[.9rem] font-medium  ">
            {t("card.Anotes")}
          </h1>
          <input
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.notes}
            placeholder={t("card.Notes")}
            className={`   ${
              isArabic ? "  text-right " : "  text-left"
            } bg-transparent   text-sec w-full h-[4rem]   px-[1rem]   text-sm  border-solid border-[1px] rounded-[0.32rem]`}
            id="notes"
          />

          <p className=" pt-1 px-1 text-xs text-gray-400">
            {" "}
            {t("card.addNote")}{" "}
          </p>
        </div>

        {/* Form Buttons */}
        <div className=" w-full pt-10 flex flex-row gap-2 ">
          {/* Cancel Button */}
          <button
            onClick={(e) => {
              e.preventDefault();
              Navto(-1);
            }}
            className=" h-[3.5rem]  text-[1.1rem]    border-solid border-[1px] text-gray-400   font-medium   w-full  rounded-[0.32rem] "
          >
            {t("card.Cancel")}
          </button>

          {/* Submit Button */}
          <button
            disabled={isLoading}
            type="submit"
            className=" h-[3.5rem] relative  text-[1.1rem] font-medium text-white w-full bg-main rounded-[0.32rem] "
          >
            {t("card.Submit")}
            <LoadingBtn color={"#fff"} isLoading={isLoading} bg={"bg-main"} />
          </button>
        </div>
      </form>
      {backImage && (
        <Cropperr
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          CardImg={backImage}
          setResult={setCbackImage}
          setImage={setBackImage}
        />
      )}

      {frontImage && (
        <Cropperr
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          CardImg={frontImage}
          setResult={setCfrontImage}
          setImage={setFrontImage}
        />
      )}
      {/* Message Component */}
      <Masseege msg={msg} setMsg={setMsg} />
    </div>
  );
}

// Exporting the AddCardManully component
export default AddCardManully;
