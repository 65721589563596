import React from "react";
import Cookies from "js-cookie";
import axios from "axios";
import Dexie from "dexie";
import { deleteCardFromLocalDB } from "../../DB/CardDB";
import { useNavigate } from "react-router-dom";

function DeleteCard({
  setDeleting,
  theme,
  setCard,
  t,
  setIsCardChange,
  setisLoading,
  IsCardChange,
  id,
}) {
  const navto = useNavigate();

  // Function to delete a card
  function deleteCard(id) {
    setisLoading(true);
    const token = Cookies.get("access_token");
    setCard((prevCards) => prevCards?.filter((i) => i.id != id));
    axios
      .delete(`/api/clients/fidelity_cards/${id}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setisLoading(false);
        setIsCardChange(!IsCardChange);
        navto("/Cards");
        deleteCardFromLocalDB(id);
      })
      .then((error) => {
        setisLoading(false);
        navto("/Cards");
      });
  }

  return (
    <div className=" z-50 fixed w-full h-full top-0 right-0 ">
      <div className=" absolute top-0 right-0 bg-black w-full h-full opacity-30"></div>
      <div
        className={`w-[70%] px-4 py-2 text-[.95rem] text-center h-[7rem] flex flex-col ${
          theme ? " bg-white" : " bg-[#212121] "
        } shadow-lg fixed top-[40%] transform translate-x-[50%] right-[50%]   rounded-md`}
      >
        <h1> {t("card.Sure")} </h1>
        <span className=" flex gap-3 w-full flex-row justify-between items-center h-full ">
          <button
            onClick={(e) => {
              setDeleting(false);
              deleteCard(id);
            }}
            className=" w-full rounded-md h-9  bg-red-400 text-white font-[600] "
          >
            {t("card.yes")}
          </button>
          <button
            onClick={(e) => {
              setDeleting(false);
              e.preventDefault();
            }}
            className=" w-full  rounded-md h-9  text-gray-500 border-solid  border-gray-400 border-[1px] "
          >
            {t("card.no")}
          </button>
        </span>
      </div>
    </div>
  );
}

export default DeleteCard;
